.input-primary{
   background-color: #3C3A32;
   color: #fff;
   border: 1px solid #fff;
   padding: 5px 10px;
   margin: 5px;
   width: 30%;
}

.input-primary::placeholder{
   color: #fff;
}

#legal{
   background-color: #3C3A32;
}

.input-legal{
   color: #fff;
   margin-top: 20px;
   margin-left: 5px;
}

.formulario .submit-button svg {
   width: 16px;
   height: 18px;
   margin-left: 10px;
   vertical-align: text-bottom;
   transition: transform 0.3s ease;
}

.formulario .submit-button svg path {
   fill: #fff;
}

.formulario .submit-button:hover svg {
   transform: translateX(5px);
}

.input-legal a{
   color: #fff;
}

.input-legal label:hover {
   cursor: pointer;
}

.submit-button{
   background-color: #E3B258;
   padding: 0px 15px;
   font-family: 'Poppins';
   font-style: normal;
   font-weight: 500;
   font-size: 13px;
   line-height: 42px;
   letter-spacing: 0.38em;
   color: #3C3A32;
   text-decoration: none;
   border: none;
}

#mensaje{
   width: 61%;
   margin-bottom: -12px;
   height: 36px;
}

input[type="checkbox"] {
   /* Oculta el checkbox predeterminado */
   appearance: none;
   -webkit-appearance: none;
   -moz-appearance: none;
   /* Establece un tamaño personalizado */
   width: 20px;
   height: 20px;
   /* Establece un fondo transparente */
   background-color: transparent;
   /* Establece el borde y el radio de esquina */
   border: 1px solid #fff;
   /* Posición y margen */
   position: relative;
   margin-right: 10px;
   top: 3px;
 }
 
 /* Estilo del marcador de verificación */
 input[type="checkbox"]::before {
   /* Crea un contenido vacío */
   content: "";
   /* Tamaño y posición del marcador */
   width: 100%;
   height: 100%;
   position: absolute;
   top: 50%;
   left: 50%;
   transform: translate(-50%, -50%);
   /* Estilo del marcador */
   background-color: #3C3A32;
   cursor: pointer;
 }
 
 /* Estilo del marcador cuando el checkbox está seleccionado */
 input[type="checkbox"]:checked::before {
   /* Cambia el color del marcador cuando el checkbox está seleccionado */
   content: '✓';
   font-family: 'Font Awesome 5 Free';
   font-weight: 900;
   font-size: 14px;
   color: #fff;
   text-align: center;
 }

 .contenedorLegalyBoton{
   display: flex;
   align-items: center;
   justify-content: space-between;
   width: 92%;
   flex-wrap: wrap;
 }

 @media(max-width: 991px) {
   .input-primary {
      width: 45%;
   }
   #mensaje {
      width: 93%;
      height: 90px;
   }
 }