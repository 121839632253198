a.href-no-style {
   text-decoration: none;
   color: inherit;
}
.info-contact-submit {
   font-family: 'poppins';
   color: #3c3a33;
   font-size: 14px;
   margin: 5px;
   display: inline-block;
   background: #f0cb87;
   padding: 5px 15px;
   border-radius: 3px;
}
.formulario{
   background: #47453C;
   padding: 100px 0px;
   position: relative;
   width: 100%;
   overflow: hidden;
}

.formulario .form-contact2{
   width: 80% !important;
}

.formulario .input-primary{
   background-color: #fff;
   color: #3C3A32;
   border: 1px solid #3C3A32;
   padding: 5px 10px;
   margin: 5px 0;
   width: 100%;
   letter-spacing: 1px;
   font-size: 14px;
   font-family: 'poppins';
}

.datosContacto .titulo span {
   font-family: 'Poppins';
}

.formulario .input-primary::placeholder{
   color: #555555;
   font-weight: 500;
}

.formulario .formularioTag{
   width: 80%;
   margin: 0 auto;
   position: relative;
}

.formulario #legal{
   background-color: #3C3A32;
}

.formulario .input-legal{
   color: #fff;
   margin-top: 20px;
   margin-left: 5px;
}

.formulario .input-legal a{
   color: #fff;
}

.formulario .submit-button{
   background: linear-gradient(106deg, #E2001A 16.45%, #FEC904 100%);
   padding: 5px 40px;
   font-family: 'Poppins';
   font-style: normal;
   font-weight: 500;
   font-size: 13px;
   letter-spacing: 0.38em;
   color: #FFF;
   text-decoration: none;
   border: none;
   position: absolute;
   bottom: 120px;
   right: 20px;
}

.formulario #mensaje{
   width: 100%;
   margin-bottom: -12px;
   height: 176px;
}

.formulario input[type="checkbox"] {
   appearance: none;
   -webkit-appearance: none;
   -moz-appearance: none;
   width: 20px;
   height: 20px;
   background-color: transparent;
   border: 1px solid #47453C;
   position: relative;
   margin-right: 10px;
   top: 3px;
 }
 
 /* Estilo del marcador de verificación */
 .formulario input[type="checkbox"]::before {
   content: "";
   width: 100%;
   height: 100%;
   position: absolute;
   top: 50%;
   left: 50%;
   transform: translate(-50%, -50%);
   background-color: #fff;
   cursor: pointer;
 }
 
 /* Estilo del marcador cuando el checkbox está seleccionado */
 .formulario input[type="checkbox"]:checked::before {
   content: '✓';
   font-family: 'Font Awesome 5 Free';
   font-weight: 900;
   font-size: 14px;
   color: #47453d;
   text-align: center;
 }

 .formulario .contenedorLegalyBoton{
   display: flex;
   align-items: center;
   justify-content: space-between;
   width: 92%;
   flex-wrap: wrap;
 }

.formulario .titulo{
   color: #FFF;
   text-align: center;
   font-size: 60px;
   font-family: 'Playfair Display';
   font-weight: 700;
   text-transform: uppercase;
   margin: 0 auto;
}

.formulario .titulo2{
   font-family: 'Poppins';
}

.formulario .image{
   display: flex;
   justify-content: center;
   margin-bottom: 50px;
}

.formulario .texto{
   color: #4A473E;
   text-align: center;
   font-size: 16px;
   font-family: Poppins;
   max-width: 1000px;
   margin: 0 auto;
}

.datosContacto{
   background: #fff;
   padding: 100px 0px 100px;
   position: relative;
   width: 100%;
   overflow: hidden;
   display: flex;
   flex-direction: column;
   justify-content: space-between;
}

.datosContacto .datos{
   display: flex;
   justify-content: center;
   flex-wrap: wrap;
}

.datosContacto .fabrica, .datosContacto .oficinas, .datosContacto .exportacion{
   display: flex;
   align-items: center;
   width: 60%;
   flex-wrap: wrap;
}

.parte1{
   width: 45%;
}

.parte2{
   width: 55%;
}

.datosContacto .exportacion .signo{
   color: #E2001A;
   font-size: 20px;
   margin-right: 10px;
   font-weight: bold;
   font-family: 'poppins';
}

.datosContacto .titulo{
   color: #47453C;
   text-align: center;
   font-family: Playfair Display;
   font-size: 60px;
   font-style: normal;
   font-weight: 700;
   text-transform: uppercase;
}

.datosContacto .titulo2{
   color: #3C3A32;
   font-family: Playfair Display;
   font-size: 20px;
   font-style: normal;
   font-weight: 700;
   text-transform: uppercase;
}

.datosContacto .texto{
   color: #4A473E;
   font-family: Poppins;
   font-size: 16px;
   font-style: normal;
   font-weight: 400;
}

.datosContacto .enlace{
   color: #E2001A;
   font-family: Poppins;
   margin-top: 10px;
   display: inline-block;
   font-style: normal;
   font-weight: 400;
   text-decoration-line: underline;
}

@media (max-width: 1170px){
   .datosContacto .fabrica, .datosContacto .oficinas, .datosContacto .exportacion{
      width: 100%;
      margin-bottom: 50px;
   }

   .parte1, .parte2{
      width: 100%;
      display: flex;
      justify-content: center;
      text-align: center;
   }
}

@media (max-width: 991px) {
   .paises .contenedorTitulo img {
      left: calc(50% + 62px);
      top: -26px;
      width: 53px;
   }
   .paises .image img{
      width: 90%;
   }
   .quesoSorprendente .detalle .textoFondo{
      font-size: 80px;
   }
   .formulario .titulo, .datosContacto .titulo{
      font-size: 50px;
   }
   .formulario .formularioTag {
      width: 100%;
      padding: 0 15px;
   }
}

@media (max-width: 768px) {
   .formulario .submit-button{
      position: initial;
      margin-bottom: 20px;
   }
   .formulario .image img {
      width: 90%;
   }
   .quesoSorprendente .detalle .textoFondo{
      font-size: 40px;
   }
   .quesoSorprendente .tituloBlanco{
      font-size: 38px !important;
      margin: 0 auto;
   }
}

@media (max-width: 326px) {
   .formulario .titulo{
      font-size: 40px !important;
      margin-bottom: 20px;
   }
}