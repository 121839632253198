.bgSomos {
   background-image: url("../../public/images/somos-cabecera-equipo.jpg");
}
.bgInnovacion {
   background-image: url("../../public/images/innovacion-cabecera.jpg");
}
.bgOvejas {
   background-image: url("../../public/images/ovejas-cabecera.jpg");
}
.bgContacto {
   background-image: url("../../public/images/contacto-cabecera.jpg");
}
.bgPremios {
   background-image: url("../../public/images/premios-cabecera.jpg");
}
.bgPrensa {
   background-image: url("../../public/images/banner-kit-prensa.jpg");
}
.cabeceraBig{
   width: 100%;
   background-size: cover;
   background-position: center;
   padding: 10% 0;
   overflow: hidden;
   position: relative;
}
.overlay::before {
   content: '';
   position: absolute;
   top: 0;
   left: 0;
   width: 100%;
   height: 100%;
   background-color: rgba(0, 0, 0, 0.5);
}
/* .cabeceraBig::before{
   content: "";
   position: absolute;
   top: 0;
   left: 0;
   width: 100%;
   background-color: rgba(60, 58, 50, 0.62);
} */
.cabeceraTituloContenedor{
   z-index: 1;
   position: relative;
   padding: 0 160px;
}
.cabecera{
   color: #FFF;
   text-align: center;
   font-size: 80px;
   font-family: 'Poppins';
   font-weight: 700;
   line-height: 1;
   text-transform: uppercase;
   margin: 0 auto 20px auto;
}
.cabeceraTexto{
   font-family: 'Playfair Display';
}
.cabeceraSubtitulo{
   color: #FFF;
   text-align: center;
   font-size: 20px;
   font-family: 'Poppins';
   line-height: 27px;
}
.quesoSorprendente{
   background: #3C3A32;
   padding: 100px 0px 100px;
   position: relative;
   width: 100%;
   overflow: hidden;
}
.detalle-lista {
   margin-top: 80px;
}
.quesoSorprendente .detalle{
   display: flex;
   flex-direction: column;
   align-items: center;
   position: relative;
}

.quesoSorprendente .detalle .textoFondo{
   color: #9A978B;
   text-align: center;
   font-size: 130px;
   font-family: Playfair Display;
   font-style: normal;
   font-weight: 700;
   line-height: 242px;
   letter-spacing: -2.92px;
   text-transform: uppercase;
   position: absolute;
   top: -45%;
   margin-top: 120px;
}

.quesoSorprendente .detalle img{
   z-index: 2;
}

.quesoSorprendente .tituloBlanco{
   color: #FFF;
   text-align: center;
   font-size: 60px;
   font-family: Playfair Display;
   font-style: normal;
   font-weight: 700;
   text-transform: uppercase;
   width: 70%;
   margin: 0 auto;
}

.quesoSorprendente .titulo2Blanco{
   font-family: Poppins;
}

.quesoSorprendente .points{
   display: flex;
   justify-content: center;
}

.quesoSorprendente .point {
   display: block;
   width: 14px;
   height: 14px;
   background-color: transparent;
   border-radius: 50%;
   border: 2px solid #9a978b;
   margin: 31px 30px 10px 30px;
   cursor: pointer;
}

.quesoSorprendente .point .selected{
   background-color: #fff;
}

.quesoSorprendente .detalle .texto{
   color: #FFF;
   text-align: center;
   font-family: Poppins;
   font-size: 20px;
   font-style: normal;
   font-weight: 400;
   line-height: 30px;
}

.tecnologiaYTradicion{
   background: linear-gradient(102deg, #E6BB6C 0%, #FFE4B4 100%);
   padding: 100px 0px 30px 0;
   position: relative;
   width: 100%;
   overflow: hidden;
}

.tecnologiaYTradicion .titulo{
   color: #47453C;
   text-align: center;
   font-size: 60px;
   font-family: 'Playfair Display';
   font-weight: 700;
   text-transform: uppercase;
   max-width: 850px;
   margin: 0 auto;
}

.tecnologiaYTradicion .titulo2{
   font-family: 'Poppins';
}

.tecnologiaYTradicion .image{
   display: flex;
   justify-content: center;
   margin-bottom: 50px;
}

.tecnologiaYTradicion .texto{
   color: #4A473E;
   text-align: center;
   font-size: 16px;
   font-family: Poppins;
   max-width: 1000px;
   margin: 0 auto;
   padding: 0 15px;
}

.paises{
   background: linear-gradient(102deg, #E6BB6C 0%, #FFE4B4 100%);
   padding: 100px 0px;
   position: relative;
   width: 100%;
   overflow: hidden;
}

.paises .texto{
   color: #3C3A32;
   text-align: center;
   font-size: 20px;
   font-family: Poppins;
   margin-top: 20px;
   margin-bottom: 0px;
}

.paises .titulo2{
   font-family: Playfair Display;
}

.paises .titulo{
   color: #3C3A32;
   text-align: center;
   font-size: 120px;
   font-family: Poppins;
   font-weight: 700;
   letter-spacing: -3.36px;
   text-transform: uppercase;
   padding-bottom: 20px;
}

.paises .contenedorTitulo{
   position: relative;
}

.paises .contenedorTitulo img{
   position: absolute;
   top: -24%;
   left: 61.5%;
   width: 100px;
}

.paises .image{
   display: flex;
   justify-content: center;
   margin-bottom: 50px;
}

.paises .image img{
   max-width: 820px;
}

.amigos{
   background-size: cover;
   background-position: center;
   padding: 100px 0px;
   position: relative;
   width: 100%;
   overflow: hidden;
   display: flex;
   flex-direction: column;
}

.amigos .image{
   display: flex;
   justify-content: center;
}

.amigos .image img{
   width: 60%;
}
.video-amigos video {
   width: 100%;
   height: auto;
}
.imagen-equipo {
   margin: 0 -15px;
}
.imagen-equipo img {
   width: 100%;
   height: auto;
}
.amigos .titulo{
   color: #3C3A32;
   text-align: center;
   font-size: 60px;
   font-family: Playfair Display;
   font-weight: 700;
   text-transform: uppercase;
   max-width: 800px;
   margin: 30px auto 0 auto;
   z-index: 1;
}

.amigos .titulo2{
   font-family: Poppins;
}

.amigos .texto{
   color: #3C3A32;
   text-align: center;
   font-size: 20px;
   font-family: Poppins;
   line-height: 1.3;
   z-index: 1;
   text-shadow: 0 0 10px #fff;
}

.equipo{
   background: linear-gradient(102deg, #E6BB6C 0%, #FFE4B4 100%);
   padding: 100px 0px;
   position: relative;
   width: 100%;
   min-width: 100%;
   overflow: hidden;
}

.equipo .texto{
   color: #fff;
   text-align: center;
   font-size: 20px;
   font-family: Poppins;
   margin-bottom: 50px;
}

.equipo .titulo{
   color: #fff;
   text-align: center;
   font-size: 120px;
   font-family: Playfair Display;
   font-weight: 700;
   line-height: 0px;
   letter-spacing: -3.36px;
   text-transform: uppercase;
   margin-bottom: 60px;
   z-index: 9;
   position: relative;
}

.equipo .tarjetas img{
   height: 350px;
}

.equipo .tarjeta{
   display: flex !important;
   flex-direction: column;
   align-items: center;
}

.equipo .slick-prev, .slick-next {
   width: 50px !important;
   z-index: 1;
}

.equipo .tarjeta img {
   -webkit-transition: all .2s ease-out;
   -moz-transition: all .2s ease-out;
   -o-transition: all .2s ease-out;
   transition: all .2s ease-out;
}

.equipo .tarjeta .nombreCarouselPersona{
   color: #3C3A32;
   text-align: center;
   font-size: 24px;
   font-family: Playfair Display;
   font-weight: 700;
   line-height: 28.5px;
   text-transform: uppercase;
   margin-top: 20px;
   padding: 0 30px;
}

.equipo .tarjeta .cargoCarouselPersona{
   color: #E41C28;
   text-align: center;
   font-size: 16px;
   font-family: Poppins;
   line-height: 1.3;
   margin-bottom: 50px;
   padding: 0 40px;
}

@media (max-width: 991px) {
   .paises .contenedorTitulo img {
      left: 50%;
      top: -3%;
      width: 90px;
   }
   .paises .image img{
      width: 90%;
   }
   .quesoSorprendente .detalle .textoFondo{
      font-size: 80px;
   }
   .paises .titulo, .tecnologiaYTradicion .titulo, .amigos .titulo, .equipo .titulo, .quesoSorprendente .tituloBlanco{
      font-size: 50px !important;
      width: 100%;
      padding: 0 15px;
   }
   .cabeceraBig,
   .cabeceraBig::before {
      padding: 210px 0 170px 0;
   }
   .cabeceraTituloContenedor {
      position: relative;
      padding: 0 15px;
   }
   .amigos {
      padding: 100px 0px 150px 0;
      min-height: unset;
   }
   .overlayWhite::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(255, 255, 255, 0.408);
   }
}

@media (max-width: 768px) {
   .tecnologiaYTradicion .image img {
      width: 90%;
   }
   .quesoSorprendente .detalle .textoFondo{
      font-size: 40px;
   }
   .quesoSorprendente .tituloBlanco{
      font-size: 38px !important;
      margin: 0 auto;
   }
   .cabeceraTituloContenedor{
      width: 98%;
   }
   .cabeceraSubtitulo {
      font-size: 16px;
      margin-top: 10px;
      line-height: 1.4;
   }
   .equipo .slick-prev {
      margin-left: 5%;
   }
   
   .equipo .slick-next {
      margin-right: 5%;
   }
}