.HomeCarrousel{
   display: flex;
   justify-content: center;
   align-items: flex-end;
   background-color: #E2001A ;
   position: relative;
   padding: 110px 15px 20px 15px;
   overflow: hidden;
}

.HomeCarrousel .image-container img {
   height: 600px;
   width: auto;
}

.HomeCarrousel .image-container .phrase {
   position: relative;
   top: -300px;
   font-size: 55px;
   text-transform: uppercase;
   color: #fff;
   font-weight: 600;
   font-family: 'Poppins';
}
.HomeCarrousel .image-container .phrase span {
   font-family: 'Playfair Display';
   color: #E6BB6C;
}

.move-rect{
   display: block;
   position: relative;
   padding: 10px 20px;
}

.tituloCarrousel{
   position: absolute;
   top: 60%;
   font-style: normal;
   font-weight: 700;
   font-size: 80px;
   z-index: 3;
   text-transform: uppercase;
   white-space: nowrap;
   text-align: center;
   color: #FFFFFF;
}

.tituloCarrouselImg{
   width: 600px;
}

.tituloCarrouselNaraja{
   font-family: 'Playfair Display';
   color: #E6BB6C;
}

.tituloQuesos, .tituloPremios{
   font-family: 'Playfair Display';
   font-style: normal;
   font-weight: 700;
   font-size: 60px;
   text-align: center;
   text-transform: uppercase;
   color: #47453C;
   line-height: 1.2;
}
.tituloQuesosResaltado, .tituloPremiosResaltado{
   font-family: 'Poppins';
   font-weight: bold;
}
.textoQuesos{
   font-family: 'Poppins';
   font-style: normal;
   font-weight: 400;
   font-size: 20px;
   text-align: center;
   color: #4A473E;
}
.textoQuesos > span{
   font-weight: bold;
}

.cajaQuesos{
   position: relative;
}

.circle {
   width: 20px;
   height: 20px;
   border-radius: 50%;
   box-shadow: 0px 0px 1px 1px #0000001a;
   background-color: #E2001A;
}

.pulse {
   animation: pulse-animation 2s infinite;
}

.cajaQuesosSub1, .cajaQuesosSub2{
   display: flex;
   justify-content: center;
   flex-wrap: wrap;
}

.cajaQuesosMovil{
   display: none;
   justify-content: center;
   flex-direction: column;
   align-items: center;
}

.calidadMovil, .diferenciacionMovil, .innovacionMovil{
   display: flex;
   align-items: center;
}

.compromisoMovil{
   display: flex;
   align-items: center;
   padding-bottom: 50px;
}

.calidadMovil .titulo, .diferenciacionMovil .titulo, .innovacionMovil .titulo, .compromisoMovil .titulo {
   font-family: 'Playfair Display';
   font-style: normal;
   font-weight: bold;
   font-size: 25px;
   color: #47453C;
   display: flex;
   align-items: center;
}

.calidadMovil img,
.diferenciacionMovil img,
.innovacionMovil img,
.compromisoMovil img {
   max-width: 200px;
}

.calidadMovil .texto, .diferenciacionMovil .texto, .innovacionMovil .texto, .compromisoMovil .texto{
   font-family: 'Poppins';
   font-style: normal;
   font-weight: 400;
   font-size: 16px;
   color: #4C4940;
}

.calidadMovil .texto .textoRojo{
   font-family: 'Playfair Display';
   font-weight: bold;
   color: #E2001A;
}

.mas2{
   font-family: 'Poppins';
   font-style: normal;
   font-weight: 400;
   font-size: 16px;
   color: #E2001A;
   padding-top: 5px;
}

.cajaQuesos span.mas {
   font-family: 'Poppins';
   font-style: normal;
   font-weight: 400;
   font-size: 16px;
   color: #fff;
   margin-right: 5px;
}

.calidad p{
   font-family: 'Playfair Display';
   font-style: normal;
   font-weight: bold;
   font-size: 25px;
   color: #47453C;
   display: flex;
   align-items: center;
   position: relative;
   top: 35%;
   left: 10%;
   cursor: pointer;
   width: fit-content;
   z-index: 1;
}

.diferenciacion p{
   font-family: 'Playfair Display';
   font-style: normal;
   font-weight: bold;
   font-size: 25px;
   color: #47453C;
   display: flex;
   align-items: center;
   position: relative;
   left: 80%;
   top: 35%;
   width: fit-content;
   cursor: pointer;
   width: fit-content;
   z-index: 3;
}
.diferenciacion{
   margin-left: -50px;
}

.innovacion p{
   font-family: 'Playfair Display';
   font-style: normal;
   font-weight: bold;
   font-size: 25px;
   color: #47453C;
   display: flex;
   align-items: center;
   position: relative;
   top: 55%;
   left: -20%;
   cursor: pointer;
   width: fit-content;
   z-index: 3;
}
.innovacion{
   margin-top: -320px;
   margin-left: 150px;
}

.compromiso p{
   font-family: 'Playfair Display';
   font-style: normal;
   font-weight: bold;
   font-size: 25px;
   color: #47453C;
   display: flex;
   align-items: center;
   position: relative;
   top: 80%;
   left: 50%;
   cursor: pointer;
   width: fit-content;
   z-index: 3;
}
.compromiso{
   margin-top: -470px;
   margin-left: -50px;
}

.quesoSeleccionado .titulo{
   font-family: 'Playfair Display';
   font-style: normal;
   font-weight: bold;
   font-size: 25px;
   color: #47453C;
   display: flex;
   align-items: center;
}

.quesoSeleccionado .texto .textoRojo{
   font-family: 'Playfair Display';
   font-weight: bold;
   color: #E2001A;
}
.quesoSeleccionado .texto{
   font-family: 'Poppins';
   font-style: normal;
   font-weight: 400;
   font-size: 16px;
   color: #4C4940;
}

.salirQuesoSeleccionado{
   position: absolute;
   top: 0%;
   left: 25%;
   font-size: 20px;
   color: #E2001A;
   cursor: pointer;
}

.quesoSeleccionado div{
   width: 20%;
}

.calidad:hover + .cajaQuesoCalidad{
   display: flex;
}

.cajaQueso {
   align-items: center;
   justify-content: center;
   position: fixed;
   width: 100%;
   top: 50%;
   left: 50%;
   transform: translate(-50%, -50%);
   z-index: 2;
}

.cajaQueso div{
   margin-left: 30px;
   border-radius: 14px;
   padding: 20px;
   background-color: #fadca6db;
   z-index: 4;
   box-shadow: 0px 0px 18px 9px #00000033;
   -webkit-transition: all .2s ease-out;
   -moz-transition: all .2s ease-out;
   -o-transition: all .2s ease-out;
   transition: all .2s ease-out;
}

.fp-is-overflow>.fp-overflow{
   height: 100%;
}

/* Procesos de elaboracion */

.procesoElaboracion{
   padding-top: 270px;
   background-color: #3C3A32;
   height: 100%;
}

.carouselQuesos .slick-prev, .slick-next{
   width: 50px !important;
   z-index: 1;
}

.carouselQuesos .slick-prev{
   margin-left: 35%;
}

.carouselQuesos .slick-next{
   margin-right: 35%;
}

.procesoElaboracion > div > div > div > div{
   padding: 270px 0px 0px;
   display: flex !important;
   justify-content: center;
   align-items: center;
}

.carouselQuesos{
   padding: 100px 0px;
   background: linear-gradient(92.24deg, #E6BB6C 20.28%, #FFE4B4 139.78%);
}

.carouselQuesos .slick-track{
   display: flex !important;
   align-items: center;
   margin-top: 50px;
}

.carouselQuesos .slick-slide > div > div{
   display: flex !important;
   justify-content: center;
   flex-direction: column;
   align-items: center;
}

.carouselQuesos .slick-slide{
   filter: blur(4px);
}

.carouselQuesos .slick-slide img {
   width: 300px;
   transition: width 0.3s ease, height 0.3s ease;
}

.carouselQuesos .slick-center{
   filter: blur(0px) !important;
}

.carouselQuesos .slick-center img {
   width: 300px;
}

.tituloCarouselQuesos{
   font-family: 'Playfair Display';
   font-style: normal;
   font-weight: 700;
   font-size: 35px;
   margin-top: 20px;
   text-align: center;
   text-transform: uppercase;
   color: #47453C;
}

.tituloCarouselQuesosSpan{
   font-family: 'Poppins';
}

.textoCarouselQuesos{
   font-family: 'Poppins';
   font-style: normal;
   font-weight: 400;
   font-size: 20px;
   text-align: center;
   color: #4A473E;
}

.enlaceBotonCarouselQuesos {
   text-decoration: none;
   background: linear-gradient(93.85deg, #E2001A 46.46%, #FEC904 175.6%);
   padding: 20px 30px;
   color: #F0CB88;
   font-family: 'Poppins';
   font-style: normal;
   letter-spacing: 4px;
   font-weight: 400;
   font-size: 18px;
   text-align: center;
   margin: 20px 0 0 0;
   -webkit-transition: all .2s ease-out;
   -moz-transition: all .2s ease-out;
   -o-transition: all .2s ease-out;
   transition: all .2s ease-out;
}
.enlaceBotonCarouselQuesos:hover {
   color: #F0CB88;
   border-radius: 10px;
}

.slick-prev:before, .slick-next:before {
   font-size: 24px !important;
   color: #47453d !important;
}

.carouselPremios{
   padding: 100px 0px 50px;
   background: linear-gradient(181.03deg, #F8F8F8 62.08%, #CFCFCF 125.46%);
   height: fit-content;
}

.textoFondoPremios{
   font-family: 'Playfair Display';
   font-style: normal;
   font-weight: 700;
   font-size: 168px;
   text-align: center;
   text-transform: uppercase;
   color: #E5E5E5;
   position: relative;
}

.enlaceBotonCarouselPremios{
   text-decoration: none;
   font-family: 'Poppins';
   font-style: normal;
   font-weight: 500;
   font-size: 24px;
   text-align: center;
   letter-spacing: 0.24em;
   text-transform: uppercase;
   color: #3C3A32;
   padding: 20px 30px;
   border-width: 5px;
   border-style: solid;
   border-image: linear-gradient(to right, #E2011B, #E93C16);
   border-image-slice: 1;
   -webkit-transition: all .2s ease-out;
   -moz-transition: all .2s ease-out;
   -o-transition: all .2s ease-out;
   transition: all .2s ease-out;
}

.enlaceBotonCarouselPremios:hover {
   background: #E2011B;
   border-color: #E2011B;
   border-image: none;
   color: #fff;
}

.premios{
   display: flex !important;
   align-items: center;
   flex-direction: column;
   justify-content: center;
   flex-wrap: wrap;
}

.premio{
   height: 250px;
}

.botonPremios{
   display: flex;
   justify-content: center;
   margin-top: 100px;
   margin-bottom: 100px;
}

.sliderCarouselPremios{
   margin-top: -80px;
}

.sliderCarouselPremios img.peana {
   position: relative;
   left: 10px;
}

.carouselPremios .slick-prev, .slick-next{
   width: 50px !important;
   z-index: 1;
}

.carouselPremios .slick-next{
   margin-right: 4%;
}
.carouselPremios .slick-prev{
   margin-left: 4%;
}

.catalogoQuesos{
   overflow: hidden;
   max-width: 100%;
   margin: 0;
}

.catalogoQuesos a {
   text-decoration: none;
}

.catalogoQuesos div{
   max-height: 390px;
}

.catalogoQuesos .fila{
   display: flex;
   flex-wrap: wrap;
}

.catalogoQuesos .columna{
   padding: 150px;
   text-decoration: none;
   position: relative;
   height: 50%;
   display: flex;
   align-items: center;
   justify-content: center;
}

.catalogoQuesos .columna:hover {
   cursor: pointer;
}

.catalogoQuesos p{
   font-family: 'Poppins';
   font-style: normal;
   font-weight: bold;
   font-size: 45px;
   line-height: 45px;
   text-align: center;
   letter-spacing: 0.05em;
   text-transform: uppercase;
   color: #FFFFFF !important;
   margin-bottom: 0px;
   display: flex;
   flex-direction: column;
   align-items: center;
   position: relative;
   z-index: 2;
}

.catalogoQuesos p > span{
   font-family: 'Playfair Display';
   font-weight: 400;
}

.carouselPremios .slick-prev:before, .carouselPremios .slick-next:before{
   color: black !important;
}

.quesosManchegos{
   background: url(../../public/images/home_quesos_manchegos.png.webp);
   background-size: cover;
   background-position: center;
   overflow: hidden;
}
.quesosManchegos:hover .foto{
   transform: skew(-35deg) translateX(100%);
}
.quesosManchegos .foto{
   position: absolute;
   top: 0px;
   left: -60%;
   width: 220%;
   height: 100%;
   background: linear-gradient(93.85deg, #F2E962 22.49%, #FEC904 73.35%);
   transition: transform 0.6s linear;
   transform: skew(-35deg);
}

.catalogoQuesos .quesosEcologicos{
   background: url(../../public/images/home_quesos_ecologicos.png.webp);
   background-size: cover;
   background-position: center;
   overflow: hidden;
}
.quesosEcologicos:hover .foto{
   transform: skew(-35deg) translateX(100%);
}
.quesosEcologicos .foto{
   position: absolute;
   top: 0px;
   left: -60%;
   width: 220%;
   height: 100%;
   background: linear-gradient(93.85deg, #62F2AD 22.49%, #00DEDE 175.6%);
   transition: transform 0.6s linear;
   transform: skew(-35deg);
}

.catalogoQuesos .quesosLecheCruda{
   background: url(../../public/images/home_quesos_manchegos_leche_cruda.png.webp);
   background-size: cover;
   background-position: center;
   overflow: hidden;
}
.quesosLecheCruda:hover .foto{
   transform: skew(-35deg) translateX(100%);
}
.quesosLecheCruda .foto{
   position: absolute;
   top: 0px;
   left: -60%;
   width: 220%;
   height: 100%;
   background: linear-gradient(93.85deg, #9062F2 22.49%, #1B053F 175.6%);
   transition: transform 0.6s linear;
   transform: skew(-35deg);
}

.quesosOveja{
   padding: 100px 15px 100px;
   background: linear-gradient(102deg, #E6BB6C 0%, #FFE4B4 100%);
   overflow: hidden;
}

.catalogoQuesos .quesosOveja{
   background: url(../../public/images/home_quesos_oveja.png.webp);
   background-size: cover;
   background-position: center;
   overflow: hidden;
}
.quesosOveja:hover .foto{
   transform: skew(-35deg) translateX(100%);
}
.quesosOveja .foto{
   position: absolute;
   top: 0px;
   left: -60%;
   width: 220%;
   height: 100%;
   background: linear-gradient(93.85deg, #FD8C4C 22.49%, #D07017 175.6%);
   transition: transform 0.6s linear;
   transform: skew(-35deg);
}

.parallax{
   min-height: 100vh;
}

.catalogoQuesos .quesosLecheCrudaOveja{
   background: url(../../public/images/home_quesos_oveja_leche_cruda.png.webp);
   background-size: cover;
   background-position: center;
   overflow: hidden;
}
.quesosLecheCrudaOveja:hover .foto{
   transform: skew(-35deg) translateX(100%);
}
.quesosLecheCrudaOveja .foto{
   position: absolute;
   top: 0px;
   left: -60%;
   width: 220%;
   height: 100%;
   background: linear-gradient(93.85deg, #C0BB66 22.49%, #83702A 175.6%);
   transition: transform 0.6s linear;
   transform: skew(-35deg);
}

.catalogoQuesos .quesosRecetasExclusivas{
   background: url(../../public/images/home_quesos_receta_exclusivas.png.webp);
   background-size: cover;
   background-position: center;
   overflow: hidden;
}
.quesosRecetasExclusivas:hover .foto{
   transform: skew(-35deg) translateX(100%);
}
.quesosRecetasExclusivas .foto{
   position: absolute;
   top: 0px;
   left: -60%;
   width: 220%;
   height: 100%;
   background: linear-gradient(93.85deg, #F26262 22.49%, #BA0505 175.6%);
   transition: transform 0.6s linear;
   transform: skew(-35deg);
}

@keyframes pulse-animation {
   0% {
     box-shadow: 0 0 0 0px rgba(0, 0, 0, 0.2);
   }
   100% {
     box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
   }
}

.hideEl{
   opacity: 0;
}

.elaboracion-control {
   position: fixed;
   right: 30px;
   top: 50%;
   transform: translate(-50%, -50%);
   z-index: 9;
}
.elaboracion-control .points .point {
   display: block;
   width: 17px;
   height: 17px;
   border: 1px solid #9A978B;
   background: transparent;
   position: relative;
   border-radius: 50%;
   margin: 30px 0;
   -webkit-transition: all .2s ease-out;
   -moz-transition: all .2s ease-out;
   -o-transition: all .2s ease-out;
   transition: all .2s ease-out;
}
.elaboracion-control .points .point.active {
   background: #fff;
   transform: scale(0.65);
   border: 0;
}

.elaboracion-control .points a:hover {
   border-color: #fff;
}

.slick-dots li button:before {
   font-size: 13px;
   margin-top: 20px;
}
.slick-dots li {
   margin: 0 13px;
}

.leche {
   background-color: #3C3A32;
   height: 100%;
}

.coagulacion {
   background-color: #464335;
   height: 100%;
}

.salado {
   background-color: #65635C;
   height: 100%;
}

.secreto {
   background-color: #535147;
   height: 100%;
}

.maduracion {
   background-color: #52473D;
   height: 100%;
}

#fp-nav ul li a span {
   background-color: #fff;
}

.tituloElaboracionSpan {
   font-family: 'Playfair Display';   
}

.tituloElaboracion {
   font-family: 'Poppins';
   font-style: normal;
   font-weight: 700;
   font-size: 60px;
   text-align: center;
   text-transform: uppercase;
   color: #FFFFFF;
   line-height: 1.2;
   padding-top: 80px;
}

.numPaso1 {
   font-family: 'Poppins';
   font-weight: 700;
   margin: 50px auto;
   text-align: center;
   color: #E2001A;
   font-size: 65px;
   font-weight: bold;
   border: 4px solid #E2001A;
   border-radius: 50%;
   width: 105px;
}

.textoFondo1 {
   font-family: 'Playfair Display';
   font-style: normal;
   font-size: 100px;
   font-weight: 600;
   text-align: center;
   letter-spacing: -0.02em;
   text-transform: uppercase;
   color: #9A978B;
}

.box-leche {
   top: -145px;
   position: relative;
   margin-bottom: 50px;
}

.imagenProcesoLeche {
   max-width: 300px;
   top: 60%;
   left: 50%;
}

.box-coagulacion {
   position: relative;
   top: -140px;
   left: 20px;
}

.imagenPiernas {
   position: absolute;
   left: calc(50% - 80px);
   top: 19px;
   z-index: 2;
   width: 130px;
}

.imagenProcesoCoagulacion2 {
   position: absolute;
   top: 24%;
   left: calc(50% - 95px);
   z-index: 3;
   width: 140px;
}

.imagenNube {
   left: 48%;
}

.box-salado {
   position: relative;
   top: -120px;
   left: 10px;
   margin-bottom: 0;
}

.brazoIzquierda {
   top: -100px;
   left: 30px;
   position: relative;
   z-index: 2;
}

.brazoDerecha {
   top: -100px;
   left: -50px;
   position: relative;
   z-index: 2;
}

.brazoIzquierda img,
.brazoDerecha img {
   width: 230px;
}

.brazoIzquierda img {
   transform: scaleX(-1) rotate(0deg);
}

.brazoDerecha img {
   transform: rotate(0deg);
}

.box-secreto {
   position: relative;
   top: -160px;
   left: 10px;
}

.colibriIzquierda {
   max-width: 300px;
   z-index: 2;
   position: relative;
   left: 17%;
   top: 20px;
   animation: moveLeftAndRight 2s linear infinite;
}

.colibriDerecha {
   max-width: 300px;
   z-index: 2;
   position: relative;
   right: 35%;
   top: -37px;
   animation: moveLeftAndRight 1s linear infinite;
}

@keyframes moveLeftAndRight {
   0%, 100% {
     transform: translateX(0);
   }
   25% {
     transform: translateX(-5px) scaleY(1.1);
   }
   50% {
     transform: translateX(0) scaleY(0.9);
   }
   75% {
     transform: translateX(5px) scaleY(1.1);
   }
}

.box-relojes {
   position: relative;
   top: -220px;
   left: -66px;
   margin-bottom: -180px;
}

.relojDerecha {
   max-width: 350px;
   right: 68%;
   top: -120px;
   position: relative;
   z-index: 9;
}

.relojIzquierda {
   max-width: 485px;
   z-index: 2;
   left: 335px;
   top: -140px;
   position: relative;
}

.textoDeProceso {
   font-family: 'Poppins';
   font-style: normal;
   font-size: 20px;
   text-align: center;
   color: #FFFFFF;
   margin: 0;
   top: -100px;
   position: relative;
}

.seccion-proceso {
   padding: 20px 15px 20px 15px;
   overflow: hidden;
}

.numPaso {
   font-family: 'Poppins';
   font-weight: 700;
   margin: 0px auto;
   text-align: center;
   color: #E2001A;
   font-size: 65px;
   font-weight: bold;
   border: 4px solid #E2001A;
   border-radius: 50%;
   width: 105px;
   transform: translateY(100px);
}

.textoFondo {
   font-family: 'Playfair Display';
   font-style: normal;
   font-size: 100px;
   font-weight: 600;
   text-align: center;
   letter-spacing: -0.02em;
   text-transform: uppercase;
   color: #9A978B;
   margin-top: 140px;
}

.imagenProcesoCoagulacion {
   position: relative;
   max-width: 440px;
   z-index: 1;
}
.imagenQueso {
   width: 340px;
}
@media (max-width: 1200px) {
   .cajaQuesos{
      display: none !important;
   }  
   .cajaQuesosMovil{
      display: flex !important;
      margin-top: 60px;
   }
}
@media (max-width: 991px) {
   .tituloQuesos, .tituloPremios{
      font-size: 50px !important;
      width: 90%;
      margin: 0 auto;
   }
   .carouselQuesos .slick-next{
      margin-right: 5% !important;
   } 
   .carouselQuesos .slick-prev{
      margin-left: 5% !important;
   }
}

@media(max-width: 768px) {
   .elaboracion-control {
      display: none;
   }
   .tituloQuesos, .tituloPremios, .tituloElaboracion{
      font-size: 28px !important;
      margin: 0 auto 15px auto;
   }
   .enlaceBotonCarouselPremios {
      font-size: 14px;
   }
   .HomeCarrousel {
      margin-top: 90px;
   }
   .HomeCarrousel .image-container .phrase {
      font-size: 50px;
      top: -240px;
   }
   .HomeCarrousel .phrase {
      display: flex;
      flex-direction: column;
      align-items: center;
   }
    .HomeCarrousel .phrase span {
      white-space: nowrap;
   }
   .HomeCarrousel .image-container img {
      height: 400px;
   }
   #elaboracion-box .textoFondo,
   #elaboracion-box .textoFondo1 {
      font-size: 40px;
   }
   .imagenProcesoLeche {
      max-width: 190px;
      margin-bottom: 50px;
   }
   .box-leche {
      top: -80px;
   }
   .textoDeProceso {
      top: -70px;
      padding: 0 20px;
   }
   .box-salado {
      top: -75px;
      left: 0;
   }
   .brazoIzquierda {
      top: -30px;
      left: 40px;
   }
   .brazoDerecha {
      top: -30px;
      left: -40px;
   }
   .brazoIzquierda img,
   .brazoDerecha img {
      width: 120px;
   }
   .box-salado img#queso {
      width: 170px;
   }
   .box-coagulacion .imagenProcesoCoagulacion {
      max-width: 350px;
      left: 20px;
   }
   .box-secreto .imagenProcesoCoagulacion,
   .box-relojes .imagenProcesoCoagulacion {
      width: 170px;
   }
   .imagenProcesoCoagulacion2 {
      width: 100px;
      top: 25%;
      left: calc(50% - 50px);
   }
   .imagenPiernas {
      width: 90px;
      left: calc(50% - 30px);
   }
   .box-coagulacion {
      top: -90px;
      left: 0;
   }
   .seccion-proceso {
      padding: 15px 15px 0 15px;
   }
   .tituloElaboracion {
      margin-top: 60px;
   }
   .colibriIzquierda {
      max-width: 100px;
      top: 0;
   }
   .colibriDerecha {
      max-width: 90px;
      top: -40px;
   }
   .box-secreto {
      top: -50px;
      left: 0;
      margin-bottom: 40px;
   }
   .relojIzquierda {
      max-width: 220px;
      top: -67px;
      left: 150px;
   }
   .relojDerecha {
      max-width: 100px;
      top: -80px;
   }
   .textoFondoPremios {
      font-size: 48px;
      margin-bottom: 80px;
   }
   .box-relojes {
      top: -100px;
      left: -50px;
      margin-bottom: -60px;
   }
   .carouselQuesos .slick-center img,
   .carouselQuesos .slick-slide img {
      width: 230px;
   }
   .tituloCarouselQuesos {
      font-size: 30px;
   }
   .textoCarouselQuesos {
      font-size: 18px;
   }
   .carouselQuesos .slick-slide {
      filter: blur(10px);
      padding-bottom: 30px;
   }
}

@media (max-width: 630px) {
   
   .tituloCarrousel{
      font-size: 40px;
   }

   .tituloCarrouselImg{
      width: 400px;
   }

}

@media (max-width: 425px){
   .calidadMovil img, .diferenciacionMovil img, .innovacionMovil img, .compromisoMovil img {
      max-width: 110px !important;
   }

   .calidadMovil, .diferenciacionMovil, .innovacionMovil, .compromisoMovil{
      width: 100%;
      margin-bottom: 50px;
   }

   .calidadMovil .texto, .diferenciacionMovil .texto, .innovacionMovil .texto, .compromisoMovil .texto{
      width: 100%;
   }
}