.queso{
   width: 500px;
}

.cunaQueso{
   width: 300px;
}

.datosQueso{
   padding: 0;
   background-color: #F9F9F9;
   min-height: 100%;
   width: 100%;
}
.datosQueso2{
   padding: 1px 0px 100px 0;
   background-color: #F9F9F9;
   height: fit-content;
   width: 100%;
}

.premioBanner{
   height: fit-content;
   display: flex;
   align-items: center;
   justify-content: center;
   flex-wrap: wrap;
   padding: 30px 0px;
}

.tituloPremio{
   font-family: 'Poppins';
   font-style: normal;
   font-weight: bold;
   font-size: 80px;
   line-height: 98px;
   text-align: center;
   text-transform: uppercase;
   color: #F9F9F9;
   margin-right: 5%;
   margin-bottom: 0;
}

.formatos{
   display: flex;
   flex-direction: column;
   align-items: center;
}

.formatosIconos{
   width: 50%;
   display: flex;
   justify-content: space-evenly;
   flex-wrap: wrap;
}

.formatoIcono{
   display: flex;
   flex-direction: column;
   align-items: center;
}

.formatoTextoIcono{
   font-family: 'Poppins';
   font-style: normal;
   font-weight: 500;
   font-size: 23px;
   line-height: 98px;
   text-align: center;
   letter-spacing: -0.02em;
   text-transform: uppercase;
   color: #47453C;
}

.formatoTitulo{
   font-family: 'Poppins';
   font-style: normal;
   font-weight: 500;
   font-size: 24px;
   line-height: 98px;
   text-align: center;
   letter-spacing: 0.24em;
   text-transform: uppercase;
   color: #807A60;
}

.formatoTexto{
   font-family: 'Poppins';
   font-style: normal;
   font-weight: 400;
   font-size: 20px;
   line-height: 26px;
   text-align: center;
   color: #4A473E;
}

.infoQueso{
   margin-top: 80px;
   padding-bottom: 80px;
}

.infoQuesoTitulo{
   color: #47453C;
   font-size: 24px;
   font-family: Poppins;
   font-weight: 500;
   letter-spacing: 5.76px;
   text-transform: uppercase;
}

.infoQuesoBloque{
   display: flex;
   align-items: flex-start;
}

.lineaInfoQueso{
   margin: 0 auto;
   width: 80%;
   opacity: 1;
}

.listaQueso li::marker{
   font-size: 21px;
}

.listaQueso li{
   font-family: 'Poppins';
   font-style: normal;
   font-weight: 400;
   font-size: 16px;
   color: #000000;
   margin-bottom: 14px;
}

.info-section2 {
   margin: 85px 0 100px 0;
}

.ideasMaridaje{
   display: flex;
   align-items: center;
}

.ideasMaridaje .ideasMaridajeTexts {
   margin-left: 25px;
}

.ideasMaridajeTitulo{
   font-family: 'Poppins';
   font-style: normal;
   font-weight: 500;
   font-size: 24px;
   letter-spacing: 0.24em;
   text-transform: uppercase;
   color: #47453C;
   margin-bottom: 10px;
}

.ideasMaridajeTexto{
   font-family: 'Poppins';
   font-style: normal;
   font-weight: 400;
   font-size: 20px;
   line-height: 26px;
   color: #000000;
}

.quesoMedio{
   width: calc(100% - 200px);
}

.divQuesoMedio{
   text-align: right;
}

.text-right{
   text-align: right;
}

/* Queso Oveja Tierno */

.tituloProductoQuesoOvejaTierno{
   color: #F2E963;
}

.premioBannerQuesoOvejaTierno{
   background: linear-gradient(106deg, #F2E963 0.95%, #FEC904 100%);
}

.lineaInfoQuesoOvejaTierno{
   border: 2px solid #F2E963;
}

.listaQuesoOvejaTierno li::marker{
   color: #F2E963;
}

/* Queso Oveja Semicurado */

.tituloProductoQuesoOvejaSemicurado{
   color: #FBBB03;
}

.premioBannerQuesoOvejaSemicurado{
   background: linear-gradient(106deg, #FBBB03 0.95%, #C7960B 100%);
}

.lineaInfoQuesoOvejaSemicurado{
   border: 2px solid #FBBB03;
}

.listaQuesoOvejaSemicurado li::marker{
   color: #FBBB03;
}

/* Queso Oveja Curado */

.tituloProductoQuesoOvejaCurado{
   color: #F7D6A4;
}

.premioBannerQuesoOvejaCurado{
   background: linear-gradient(106deg, #F7D6A4 0.95%, #FAAA31 100%);
}

.lineaInfoQuesoOvejaCurado{
   border: 2px solid #F7D6A4;
}

.listaQuesoOvejaCurado li::marker{
   color: #F7D6A4;
}

/* Queso Oveja Añejo */

.tituloProductoQuesoOvejaAnejo{
   color: #69412F;
}

.premioBannerQuesoOvejaAnejo{
   background: linear-gradient(106deg, #69412F 0.95%, #985C41 100%);
}

.lineaInfoQuesoOvejaAnejo{
   border: 2px solid #69412F;
}

.listaQuesoOvejaAnejo li::marker{
   color: #69412F;
}

/* Queso Manchego Curado*/

.tituloProductoQuesoManchegoCurado{
   color: #EA5418;
}

.premioBannerQuesoManchegoCurado{
   background: linear-gradient(106deg, #EA5418 16.45%, #FEC904 100%);
}

.lineaInfoQuesoManchegoCurado{
   border: 2px solid #EA5418;
}

.listaQuesoManchegoCurado li::marker{
   color: #EA5418;
}

/* Queso Manchego Semicurado*/

.tituloProductoQuesoManchegoSemicurado{
   color: #78B829;
}

.premioBannerQuesoManchegoSemicurado{
   background: linear-gradient(106deg, #78B829 16.45%, #FEC904 100%);
}

.lineaInfoQuesoManchegoSemicurado{
   border: 2px solid #78B829;
}

.listaQuesoManchegoSemicurado li::marker{
   color: #78B829;
}

/* Queso Manchego Añejo*/

.tituloProductoQuesoManchegoAnejo{
   color: #468FC9;
}

.premioBannerQuesoManchegoAnejo{
   background: linear-gradient(106deg, #468FC9 16.45%, #1CBABA 100%);
}

.lineaInfoQuesoManchegoAnejo{
   border: 2px solid #468FC9;
}

.listaQuesoManchegoAnejo li::marker{
   color: #468FC9;
}

/* Queso Manchego Semicurado Ecológico */

.tituloProductoQuesoManchegoESemicurado{
   color: #007132;
}

.premioBannerQuesoManchegoESemicurado{
   background: linear-gradient(106deg, #007132 16.45%, #1CBABA 100%);
}

.lineaInfoQuesoManchegoESemicurado{
   border: 2px solid #007132;
}

.listaQuesoManchegoESemicurado li::marker{
   color: #007132;
}

/* Queso Manchego Curado Ecológico */

.tituloProductoQuesoManchegoECurado{
   color: #00A3C6;
}

.premioBannerQuesoManchegoECurado{
   background: linear-gradient(106deg, #00A3C6 16.45%, #7BF3F3 100%);
}

.lineaInfoQuesoManchegoECurado{
   border: 2px solid #00A3C6;
}

.listaQuesoManchegoECurado li::marker{
   color: #00A3C6;
}

/* Queso Manchego Curado Cruda */

.tituloProductoQuesoManchegoCCurado{
   color: #1D1E1B;
}

.premioBannerQuesoManchegoCCurado{
   background: linear-gradient(106deg, #1D1E1B 16.45%, #746D68 100%);
}

.lineaInfoQuesoManchegoCCurado{
   border: 2px solid #1D1E1B;
}

.listaQuesoManchegoCCurado li::marker{
   color: #1D1E1B;
}

/* Queso Manchego Semicurado Cruda */

.tituloProductoQuesoManchegoCSemicurado{
   color: #1F493F;
}

.premioBannerQuesoManchegoCSemicurado{
   background: linear-gradient(106deg, #1F493F 16.45%, #00983E 100%);
}

.lineaInfoQuesoManchegoCSemicurado{
   border: 2px solid #1F493F;
}

.listaQuesoManchegoCSemicurado li::marker{
   color: #1F493F;
}

/* Queso Manchego Añejo Cruda */

.tituloProductoQuesoManchegoCAnejo{
   color: #2B3087;
}

.premioBannerQuesoManchegoCAnejo{
   background: linear-gradient(106deg, #2B3087 16.45%, #1B89C7 100%);
}

.lineaInfoQuesoManchegoCAnejo{
   border: 2px solid #2B3087;
}

.listaQuesoManchegoCAnejo li::marker{
   color: #2B3087;
}

/* Queso Oveja Curado Cruda */

.tituloProductoQuesoOvejaCCurado{
   color: #9A4230;
}

.premioBannerQuesoOvejaCCurado{
   background: linear-gradient(106deg, #9A4230 16.45%, #F6DE00 100%);
}

.lineaInfoQuesoOvejaCCurado{
   border: 2px solid #9A4230;
}

.listaQuesoOvejaCCurado li::marker{
   color: #9A4230;
}

/* Queso Oveja Semicurado Cruda */

.tituloProductoQuesoOvejaCSemicurado{
   color: #2A4F25;
}

.premioBannerQuesoOvejaCSemicurado{
   background: linear-gradient(106deg, #2A4F25 16.45%, #C9A93F 100%);
}

.lineaInfoQuesoOvejaCSemicurado{
   border: 2px solid #2A4F25;
}

.listaQuesoOvejaCSemicurado li::marker{
   color: #2A4F25;
}

/* Queso Oveja Añejo Cruda */

.tituloProductoQuesoOvejaCAnejo{
   color: #1E130E;
}

.premioBannerQuesoOvejaCAnejo{
   background: linear-gradient(106deg, #1E130E 16.45%, #FCCE00 100%);
}

.lineaInfoQuesoOvejaCAnejo{
   border: 2px solid #1E130E;
}

.listaQuesoOvejaCAnejo li::marker{
   color: #1E130E;
}

/* Queso Oveja Exclusivo Ahumado */

.tituloProductoQuesoOvejaEAhumado{
   color: #6C2209;
}

.premioBannerQuesoOvejaEAhumado{
   background: linear-gradient(106deg, #6C2209 16.45%, #D89061 100%);
}

.lineaInfoQuesoOvejaEAhumado{
   border: 2px solid #6C2209;
}

.listaQuesoOvejaEAhumado li::marker{
   color: #6C2209;
}

/* Queso Oveja Exclusivo Anchoa */

.tituloProductoQuesoOvejaEAnchoa{
   color: #004A99;
}

.premioBannerQuesoOvejaEAnchoa{
   background: linear-gradient(106deg, #004A99 16.45%, #30A9DE 100%);
}

.lineaInfoQuesoOvejaEAnchoa{
   border: 2px solid #004A99;
}

.listaQuesoOvejaEAnchoa li::marker{
   color: #004A99;
}

/* Queso Oveja Exclusivo Boletus */

.tituloProductoQuesoOvejaEBoletus{
   color: #CAAF86;
}

.premioBannerQuesoOvejaEBoletus{
   background: linear-gradient(106deg, #CAAF86 16.45%, #9F865C 100%);
}

.lineaInfoQuesoOvejaEBoletus{
   border: 2px solid #CAAF86;
}

.listaQuesoOvejaEBoletus li::marker{
   color: #CAAF86;
}

/* Queso Oveja Exclusivo Aceituna Negra */

.tituloProductoQuesoOvejaEAceitunaN{
   color: #18442D;
}

.premioBannerQuesoOvejaEAceitunaN{
   background: linear-gradient(106deg, #18442D 16.45%, #39A76D 100%);
}

.lineaInfoQuesoOvejaEAceitunaN{
   border: 2px solid #18442D;
}

.listaQuesoOvejaEAceitunaN li::marker{
   color: #18442D;
}

/* Queso Oveja Exclusivo Ajo Negro */

.tituloProductoQuesoOvejaEAjoN{
   color: #582673;
}

.premioBannerQuesoOvejaEAjoN{
   background: linear-gradient(106deg, #582673 16.45%, #BC2FD2 100%);
}

.lineaInfoQuesoOvejaEAjoN{
   border: 2px solid #582673;
}

.listaQuesoOvejaEAjoN li::marker{
   color: #582673;
}

/* Queso Oveja Exclusivo Truja Negra */

.tituloProductoQuesoOvejaETrufaN{
   color: #C6111C;
}

.premioBannerQuesoOvejaETrufaN{
   background: linear-gradient(106deg, #C6111C 16.45%, #FB1F96 100%);
}

.lineaInfoQuesoOvejaETrufaN{
   border: 2px solid #C6111C;
}

.listaQuesoOvejaETrufaN li::marker{
   color: #C6111C;
}

/* Queso Oveja Exclusivo Cruda Truja Negra */

.tituloProductoQuesoOvejaCETrufaN{
   color: #C0CBD0;
}

.premioBannerQuesoOvejaCETrufaN{
   background: linear-gradient(106deg, #C0CBD0 16.45%, #A6A5A2 100%);
}

.lineaInfoQuesoOvejaCETrufaN{
   border: 2px solid #C0CBD0;
}

.listaQuesoOvejaCETrufaN li::marker{
   color: #C0CBD0;
}

/* Queso Oveja Exclusivo Truja Blanca */

.tituloProductoQuesoOvejaETrufaB{
   color: #F9B422;
}

.premioBannerQuesoOvejaETrufaB{
   background: linear-gradient(106deg, #F9B422 16.45%, #F6DE00 100%);
}

.lineaInfoQuesoOvejaETrufaB{
   border: 2px solid #F9B422;
}

.listaQuesoOvejaETrufaB li::marker{
   color: #F9B422;
}

/* Queso Oveja Exclusivo Diablo */

.tituloProductoQuesoOvejaEDiablo{
   color: #A81921;
}

.premioBannerQuesoOvejaEDiablo{
   background: linear-gradient(106deg, #A81921 16.45%, #613639 100%);
}

.lineaInfoQuesoOvejaEDiablo{
   border: 2px solid #A81921;
}

.listaQuesoOvejaEDiablo li::marker{
   color: #A81921;
}

/* Queso Oveja Exclusivo Jalapeños */

.tituloProductoQuesoOvejaEJalapenos{
   color: #057A37;
}

.premioBannerQuesoOvejaEJalapenos{
   background: linear-gradient(106deg, #057A37 16.45%, #29E87B 100%);
}

.lineaInfoQuesoOvejaEJalapenos{
   border: 2px solid #057A37;
}

.listaQuesoOvejaEJalapenos li::marker{
   color: #057A37;
}

/* Queso Oveja Exclusivo Infierno */

.tituloProductoQuesoOvejaEInfierno{
   color: #FF010B;
}

.premioBannerQuesoOvejaEInfierno{
   background: linear-gradient(106deg, #FF010B 16.45%, #FFB400 58.12%);
}

.lineaInfoQuesoOvejaEInfierno{
   border: 2px solid #FF010B;
}

.listaQuesoOvejaEInfierno li::marker{
   color: #FF010B;
}

/* Queso Oveja Exclusivo Miel y Oregano */

.tituloProductoQuesoOvejaEMyO{
   color: #9A4230;
}

.premioBannerQuesoOvejaEMyO{
   background: linear-gradient(106deg, #9A4230 16.45%, #F6DE00 100%);
}

.lineaInfoQuesoOvejaEMyO{
   border: 2px solid #9A4230;
}

.listaQuesoOvejaEMyO li::marker{
   color: #9A4230;
}

/* Queso Oveja Exclusivo Romero */

.tituloProductoQuesoOvejaERomero{
   color: #334E21;
}

.premioBannerQuesoOvejaERomero{
   background: linear-gradient(106deg, #334E21 16.45%, #F2CB77 100%);
}

.lineaInfoQuesoOvejaERomero{
   border: 2px solid #334E21;
}

.listaQuesoOvejaERomero li::marker{
   color: #334E21;
}

/* Queso Oveja Exclusivo Tradición */

.tituloProductoQuesoOvejaETradicion{
   color: #F32424;
}

.premioBannerQuesoOvejaETradicion{
   background: linear-gradient(106deg, #F32424 16.45%, #FE8B7B 65.63%);
}

.lineaInfoQuesoOvejaETradicion{
   border: 2px solid #F32424;
}

.listaQuesoOvejaETradicion li::marker{
   color: #F32424;
}

/* Queso Oveja Ahumado */

.tituloProductoQuesoOvejaAhumado{
   color: #6C2209;
}

.premioBannerQuesoOvejaAhumado{
   background: linear-gradient(106deg, #6C2209 16.45%, #D89061 100%);
}

.lineaInfoQuesoOvejaAhumado{
   border: 2px solid #6C2209;
}

.listaQuesoOvejaAhumado li::marker{
   color: #6C2209;
}

/* Queso Oveja Anchoa */

.tituloProductoQuesoOvejaAnchoa{
   color: #004A99;
}

.premioBannerQuesoOvejaAnchoa{
   background: linear-gradient(106deg, #004A99 16.45%, #30A9DE 100%);
}

.lineaInfoQuesoOvejaAnchoa{
   border: 2px solid #004A99;
}

.listaQuesoOvejaAnchoa li::marker{
   color: #004A99;
}

/* Queso Oveja Boletus */

.tituloProductoQuesoOvejaBoletus{
   color: #CAAF86;
}

.premioBannerQuesoOvejaBoletus{
   background: linear-gradient(106deg, #CAAF86 16.45%, #9F865C 100%);
}

.lineaInfoQuesoOvejaBoletus{
   border: 2px solid #CAAF86;
}

.listaQuesoOvejaBoletus li::marker{
   color: #CAAF86;
}

/* Queso Oveja Aceituna Negra */

.tituloProductoQuesoOvejaAceitunaN{
   color: #18442D;
}

.premioBannerQuesoOvejaAceitunaN{
   background: linear-gradient(106deg, #18442D 16.45%, #39A76D 100%);
}

.lineaInfoQuesoOvejaAceitunaN{
   border: 2px solid #18442D;
}

.listaQuesoOvejaAceitunaN li::marker{
   color: #18442D;
}

/* Queso Oveja Ajo Negro */

.tituloProductoQuesoOvejaAjoN{
   color: #582673;
}

.premioBannerQuesoOvejaAjoN{
   background: linear-gradient(106deg, #582673 16.45%, #BC2FD2 100%);
}

.lineaInfoQuesoOvejaAjoN{
   border: 2px solid #582673;
}

.listaQuesoOvejaAjoN li::marker{
   color: #582673;
}

/* Queso Oveja Trufa Negra */

.tituloProductoQuesoOvejaTrufaN{
   color: #C6111C;
}

.premioBannerQuesoOvejaTrufaN{
   background: linear-gradient(106deg, #C6111C 16.45%, #FB1F96 100%);
}

.lineaInfoQuesoOvejaTrufaN{
   border: 2px solid #C6111C;
}

.listaQuesoOvejaTrufaN li::marker{
   color: #C6111C;
}

/* Queso Oveja Leche Cruda Trufa Negra */

.tituloProductoQuesoOvejaCrudaTrufaN{
   color: #C0CBD0;
}

.premioBannerQuesoOvejaCrudaTrufaN{
   background: linear-gradient(106deg, #C0CBD0 16.45%, #A6A5A2 100%);
}

.lineaInfoQuesoOvejaCrudaTrufaN{
   border: 2px solid #C0CBD0;
}

.listaQuesoOvejaCrudaTrufaN li::marker{
   color: #C0CBD0;
}

/* Queso Oveja Trufa Blanca */

.tituloProductoQuesoOvejaTrufaB{
   color: #F9B422;
}

.premioBannerQuesoOvejaTrufaB{
   background: linear-gradient(106deg, #F9B422 16.45%, #F6DE00 100%);
}

.lineaInfoQuesoOvejaTrufaB{
   border: 2px solid #F9B422;
}

.listaQuesoOvejaTrufaB li::marker{
   color: #F9B422;
}

/* Queso Oveja Diablo */

.tituloProductoQuesoOvejaDiablo{
   color: #A81921;
}

.premioBannerQuesoOvejaDiablo{
   background: linear-gradient(106deg, #A81921 16.45%, #613639 100%);
}

.lineaInfoQuesoOvejaDiablo{
   border: 2px solid #A81921;
}

.listaQuesoOvejaDiablo li::marker{
   color: #A81921;
}

/* Queso Oveja Jalapeños */

.tituloProductoQuesoOvejaJalapenos{
   color: #057A37;
}

.premioBannerQuesoOvejaJalapenos{
   background: linear-gradient(106deg, #057A37 16.45%, #29E87B 100%);
}

.lineaInfoQuesoOvejaJalapenos{
   border: 2px solid #057A37;
}

.listaQuesoOvejaJalapenos li::marker{
   color: #057A37;
}

/* Queso Oveja Infierno */

.tituloProductoQuesoOvejaInfierno{
   color: #FF010B;
}

.premioBannerQuesoOvejaInfierno{
   background: linear-gradient(106deg, #FF010B 16.45%, #FFB400 58.12%);
}

.lineaInfoQuesoOvejaInfierno{
   border: 2px solid #FF010B;
}

.listaQuesoOvejaInfierno li::marker{
   color: #FF010B;
}

/* Queso Oveja Miel y Oregano */

.tituloProductoQuesoOvejaMielOregano{
   color: #9A4230;
}

.premioBannerQuesoOvejaMielOregano{
   background: linear-gradient(106deg, #9A4230 16.45%, #F6DE00 100%);
}

.lineaInfoQuesoOvejaMielOregano{
   border: 2px solid #9A4230;
}

.listaQuesoOvejaMielOregano li::marker{
   color: #9A4230;
}

/* Queso Oveja Romero */

.tituloProductoQuesoOvejaRomero{
   color: #334E21;
}

.premioBannerQuesoOvejaRomero{
   background: linear-gradient(106deg, #334E21 16.45%, #F2CB77 100%);
}

.lineaInfoQuesoOvejaRomero{
   border: 2px solid #334E21;
}

.listaQuesoOvejaRomero li::marker{
   color: #334E21;
}

/* Queso Oveja Tradición */

.tituloProductoQuesoOvejaTradicion{
   color: #F32424;
}

.premioBannerQuesoOvejaTradicion{
   background: linear-gradient(106deg, #F32424 16.45%, #FE8B7B 65.63%);
}

.lineaInfoQuesoOvejaTradicion{
   border: 2px solid #F32424;
}

.listaQuesoOvejaTradicion li::marker{
   color: #F32424;
}

/* Queso crema ahumado */

.titulo-producto-queso-crema-ahumado,
.lista-queso-crema-ahumado li::marker {
   color: #E19B5E;
}

.linea-queso-crema-ahumado {
   border: 2px solid #E19B5E;
}

/* Queso crema ajo-negro */

.titulo-producto-queso-crema-ajo-negro,
.lista-queso-crema-ajo-negro li::marker {
   color: #A67DA9;
}

.linea-queso-crema-ajo-negro {
   border: 2px solid #A67DA9;
}

/* Queso crema diablo */

.titulo-producto-queso-crema-diablo,
.lista-queso-crema-diablo li::marker {
   color: #D4443C;
}

.linea-queso-crema-diablo {
   border: 2px solid #D4443C;
}

/* Queso crema oregano-miel */

.titulo-producto-queso-crema-oregano-miel,
.lista-queso-crema-oregano-miel li::marker {
   color: #DB990D;
}

.linea-queso-crema-oregano-miel {
   border: 2px solid #DB990D;
}

/* Queso crema romero */

.titulo-producto-queso-crema-romero,
.lista-queso-crema-romero li::marker {
   color: #219C64;
}

.linea-queso-crema-romero {
   border: 2px solid #219C64;
}

/* Queso crema trufa */

.titulo-producto-queso-crema-trufa,
.lista-queso-crema-trufa li::marker {
   color: #808385;
}

.linea-queso-crema-trufa {
   border: 2px solid #808385;
}

@media (max-width: 992px) {
   .quesoMedio{
      width: 300px;
      margin-bottom: 50px;
   }

   .divQuesoMedio{
      text-align: center;
   }

   .PrincipalProducto{
      overflow-x: hidden;
   }
}

@media (max-width: 906px) {
   .queso {
      width: 400px;
   }

   .cunaQueso{
      width: 200px;
   }

   .textoVueltas{ 
      top: 80%;
   }

   .datosQueso {
      padding: 60px 0px 0px;
   }

   .tituloPremio{
      font-size: 70px;
   }
   .ideasMaridaje {
      width: 75% !important;
   }
   .ideasMaridaje .ideasMaridajeTitulo {
      text-align: center;
      margin-top: 15px;
   }
   .ideasMaridaje .ideasMaridajeTexts {
      margin-left: 0;
   }
   .info-section2 {
      margin: 115px 0 0 0;
   }
   .datosQueso {
      padding-bottom: 30px !important;
   }
}

@media (max-width: 426px) {
   .supertextoProducto{
      margin-bottom: 0px;
      padding-bottom: 0px;
   }

   .infoQueso{
      margin-top: 20px;
      padding-bottom: 20px;
   }

   .datosQueso, .datosQueso2{
      padding: 0px;
   }

   .datosQueso2 > div{
      margin-top: 0px !important;
      padding-bottom: 70px !important;
   }
   
   .listaQueso li{
      margin-bottom: 20px;
   }

   .ideasMaridaje{
      flex-direction: column;
   }
   
   .infoQuesoBloque{
      flex-direction: column;
      align-items: center;
      text-align: center;
      margin-bottom: 40px;
   }

   .infoQuesoBloque > img{
      margin-bottom: 20px;
   }

   .infoQuesoBloque > div{
      width: 100% !important;
   }
}

@media (max-width: 390px) {
   .ocultarmovil{
      display: none;
   }

   .datosQueso {
      padding: 100px 0px 0px;
   }

   .tituloPremio{
      font-size: 50px;
   }

   .datosQueso{
      padding: 0px;
   }
}

@media (max-width: 350px) {
   .formatosIconos{
      width: 50%;
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
   }

   .tituloProducto{
      font-size: 40px !important;
   }
   
   .infoQuesoTitulo{
      font-size: 16px;
   }

   .quesoMedio{
      width: 175px;
   }

   .datosQueso{
      padding-bottom: 40px;
   }

   .listaQueso li{
      font-size: 16px;
   }
}