.ovejas .lineaRojaQuesos {
   margin-bottom: 60px;
}

.cabeceraOvejas {
   height: 100vh;
   width: 100%;
   background-size: cover;
   background-position: center;
   position: relative;
}

.cabeceraOvejas::before {
   content: "";
   position: absolute;
   top: 0;
   left: 0;
   width: 100%;
   height: 100%;
   background-color: rgba(60, 58, 50, 0.62);
}

.natural {
   background: linear-gradient(102deg, #E6BB6C 0%, #FFE4B4 100%);
   padding: 100px 0px 100px;
   position: relative;
   width: 100%;
   overflow: hidden;
}

.natural .titulo {
   color: #47453C;
   text-align: center;
   font-size: 60px;
   font-family: Playfair Display;
   font-style: normal;
   font-weight: 700;
   text-transform: uppercase;
}

.natural .titulo2 {
   font-family: 'Poppins';
}

.natural .images {
   width: 80%;
   margin: 0 auto;
   margin-top: 50px;
   display: flex;
   justify-content: space-around;
   flex-wrap: wrap;
   align-items: baseline;
}

.natural .images .image {
   display: flex;
   flex-direction: column;
   align-items: center;
   width: 350px;
   margin-left: 15px;
   margin-right: 15px;
}

.natural .images .image img {
   height: 200px;
}

.natural .images .image .titulo {
   color: #47453C;
   text-align: center;
   font-size: 20px;
   font-family: Playfair Display;
   font-style: normal;
   font-weight: 700;
   text-transform: uppercase;
   margin-top: 10px;
   margin-bottom: 20px;
}

.natural .images .image .texto {
   color: #47453C;
   text-align: center;
   font-size: 16px;
   font-family: Poppins;
   font-style: normal;
   font-weight: 400;
}

.ovejasManchegas {
   background: linear-gradient(102deg, #E6BB6C 0%, #FFE4B4 100%);
   padding: 180px 0px 0px;
   position: relative;
   width: 100%;
   overflow: hidden;
}

.ovejasManchegas .texto {
   color: #3C3A32;
   text-align: center;
   font-size: 16px;
   font-family: Poppins;
   font-style: normal;
   font-weight: 400;
   width: 70%;
   margin: 0 auto;
   margin-bottom: 100px;
}

.lineaRojaQuesos2 {
   position: relative;
   width: 73px;
   height: 10px;
   left: calc(50% - 73px/2 - 0.5px);
   background: linear-gradient(93.85deg, #E2001A 46.46%, #FEC904 175.6%);
   margin-top: 20px;
   margin-bottom: 20px;
}

.ovejasManchegas .titulo {
   color: #47453C;
   text-align: center;
   font-size: 80px;
   font-family: Playfair Display;
   font-style: normal;
   font-weight: 700;
   line-height: 98px;
   text-transform: uppercase;
   width: 80%;
   margin: 0 auto;
}

.ovejasManchegas .titulo2 {
   font-family: Poppins;
}

.ovejasManchegas .tarjetas img {
   width: 325px;
}

.ovejasManchegas .tarjeta {
   display: flex !important;
   flex-direction: column;
   align-items: center;
}

.ovejasManchegas .slick-prev {
   margin-left: 5%;
}

.ovejasManchegas .slick-next {
   margin-right: 5%;
}

.ovejasManchegas .slick-prev,
.slick-next {
   width: 50px !important;
   z-index: 1;
}

.ovejasManchegas .tarjeta .nombreCarouselPersona {
   color: #3C3A32;
   text-align: center;
   font-size: 24px;
   font-family: Playfair Display;
   font-weight: 700;
   line-height: 28.5px;
   text-transform: uppercase;
   margin-top: 20px;
}

.ovejasManchegas .tarjeta .cargoCarouselPersona {
   color: #E41C28;
   text-align: center;
   font-size: 20px;
   font-family: Poppins;
   line-height: 35.5px;
   margin-bottom: 50px;
}

.lecheCalidadSabor {
   background: linear-gradient(185deg, #F8F8F8 0%, #CFCFCF 100%);
   padding: 100px 0px;
   position: relative;
   width: 100%;
   min-height: 100vh;
   overflow: hidden;
   position: relative;
}

.lecheCalidadSabor .detalle {
   display: flex;
   flex-direction: column;
   align-items: center;
   position: relative;
}

.lecheCalidadSabor .detalle .textoFondo {
   color: #9A978B;
   text-align: center;
   font-family: Playfair Display;
   font-size: 100px;
   font-style: normal;
   font-weight: 700;
   line-height: 242px;
   letter-spacing: -3.68px;
   text-transform: uppercase;
   position: absolute;
   top: -235px;
}

.lecheCalidadSabor .detalle.up-julieta .textoFondo {
   top: -285px;
}

.lecheCalidadSabor .detalle img {
   width: 440px;
   height: auto;
   z-index: 2;
}

.lecheCalidadSabor .titulo {
   color: #47453C;
   text-align: center;
   font-family: Playfair Display;
   font-size: 60px;
   font-style: normal;
   font-weight: 700;
   text-transform: uppercase;
   width: 80%;
   margin: 0 auto;
}

.lecheCalidadSabor .titulo2Blanco {
   font-family: Poppins;
}

.lecheCalidadSabor .texto {
   color: #3C3A32;
   text-align: center;
   font-family: Poppins;
   font-size: 16px;
   font-style: normal;
   font-weight: 400;
   width: 70%;
   margin: 0 auto;
   margin-bottom: 80px;
}

.lecheCalidadSabor .points {
   position: absolute;
   display: flex;
   justify-content: center;
   margin-bottom: 100px;
   flex-direction: column;
   margin-right: 30px;
   top: 50%;
   right: 0px;
   transform: translateY(-50%);
}

.lecheCalidadSabor .point {
   display: block;
   width: 20px;
   height: 20px;
   background-color: transparent;
   border-radius: 50%;
   border: 2px solid #9A978B;
   margin: 5px;
   cursor: pointer;
}

.lecheCalidadSabor .detalle .texto {
   color: #47453C;
   text-align: center;
   font-family: Poppins;
   font-size: 16px;
   font-style: normal;
   font-weight: normal;
   line-height: 27px;
}

.box-quesos-oveja {
   margin-top: 140px;
}

@media (max-width: 991px) {

   .natural .images .image .titulo {
      font-size: 20px !important;
   }

   .lecheCalidadSabor .texto {
      margin-bottom: 100px;
   }

   .paises .contenedorTitulo img {
      left: 50%;
      top: -3%;
      width: 90px;
   }

   .paises .image img {
      width: 90%;
   }

   .lecheCalidadSabor .detalle .textoFondo {
      font-size: 73px;
   }

   .cabeceraOvejas .cabecera,
   .cabeceraOvejas .cabecera .cabeceraTexto,
   .natural .titulo,
   .lecheCalidadSabor .titulo {
      font-size: 50px !important;
      width: 90%;
      margin: 0 auto;
   }

   .lecheCalidadSabor .detalle.up-julieta .textoFondo {
      top: -255px;
   }

   .lecheCalidadSabor .detalle .textoFondo {
      top: -225px;
   }

   #julieta {
      padding-top: 25px !important;
   }

   .lecheCalidadSabor .detalle img {
      padding-top: 10px !important;
   }

   .natural .images .image .texto {
      margin-bottom: 50px;
   }
}

@media (max-width: 768px) {

   .lecheCalidadSabor .titulo {
      font-size: 48px !important;
      margin: 0 auto;
   }

   .natural .images .image .titulo {
      font-size: 28px !important;
   }

   .cabeceraOvejas .cabecera,
   .cabeceraOvejas .cabecera .cabeceraTexto {
      font-size: 44px !important;
      margin: 0 auto;
      line-height: 1.3;
   }

   .cabeceraTituloContenedor {
      width: 98%;
   }

   .lecheCalidadSabor .detalle img {
      padding-top: 20px !important;
      width: 300px;
   }
}