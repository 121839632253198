.iconos-sociales {
   position: relative;
   display: inline-block;
   top: 3px;
}
.iconos-sociales .icono-instagram,
.iconos-sociales .icono-facebook {
   background: #fff;
   display: inline-block;
   width: 20px;
   height: 20px;
   border-radius: 50%;
   margin: 0 6px;
}
.iconos-sociales img {
   width: 20px;
   height: 20px;
   padding: 2px;
}
.iconos-sociales .icono-instagram img {
   top: -4px;
   position: relative;
   width: 22px;
   height: 22px;
   left: -1px;
}
.iconos-sociales .icono-facebook img {
   top: -1px;
   position: relative;
}
.iconosFooter .iconos-sociales {
   margin-right: 15px;
}
.navbar-toggler-icon {
   background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255,255,255,1)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
}
.navbar-toggler {
   border: 1px solid #fff;
   outline: none !important;
   box-shadow: none !important;
}
.simple-page {
   padding: 50px 0;
}
.simple-page h1 {
   margin-bottom: 30px;
}

.menu-quesos-desktop,
.menu-quesos-mobile {
   display: none;
}
.menu-quesos-mobile {
   padding: 0 8px;
}
.menu-quesos-mobile a.submenu-mobile {
   display: block;
   text-decoration: none;
   color: #3c3a32;
   font-weight: 600;
   text-transform: uppercase;
   font-size: 14px;
   padding: 8px 0;
}
.menu-quesos-mobile a.item-submenu-mobile {
   display: block;
   text-decoration: none;
   color: #3c3a32;
   font-weight: 400;
   font-size: 14px;
   padding: 8px 3px 8px 15px;
}
a:hover {
   color: #BA0505;
}
.dropDownSection .dropdown-item:active,
.dropDownSection .dropdown-item:hover,
.dropdown-item.active {
   background-color: initial;
}
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.lineaRojaQuesos {
   position: relative;
   width: 73px;
   height: 10px;
   left: calc(50% - 73px/2 - 0.5px);
   background: linear-gradient(93.85deg, #E2001A 46.46%, #FEC904 175.6%);
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.center{ 
   display:flex; 
   justify-content: center; 
   align-items: center;
}

/* navbar */
.navbar{
   display: flex;
   flex-direction: column;
   background-color: #3C3A32;
   z-index: 10;
   position: fixed;
   width: 100%;
   padding-bottom: 10px;
}

.nav-link,
.navbar-nav .nav-link.active,
.navbar-nav .show>.nav-link {
   font-size: 13px;
   color: #fff;
   border-right: 1px solid #fff;
   padding: 0px 15px !important;
   letter-spacing: 0.12em;
   font-family: 'Poppins';
   font-style: normal;
   font-weight: 500;
}

.nav-link:hover,
.nav-link:active,
.nav-link:focus,
.topMenu_lang .topMenu-nav-link:hover {
   color: #e3001a;
}

.dropDownSection .dropdown-item:hover {
   color: #000;
}

.nav-item img {
   height: 95px;
   width: auto;
} 

#quesos-dropdown{
   border-right: 1px solid #fff;
}

.nav-link:last-child,
.navbar-nav .nav-link:last-child,
.navbar-nav .show>.nav-link:last-child {
   border-right: none;
}
.navbar-nav .dropdown-toggle.nav-link {
   border-right: 1px solid #fff;
}

.topMenu-nav-link{
   font-size: 18px;
   color: #fff;
   text-decoration: none;
   font-family: 'Poppins';
   font-style: normal;
   font-weight: 500;
   font-size: 11px;
}

.topMenu_lang{
   display: flex;
   align-items: center;
}

.topMenu_lang .topMenu-nav-link{
   letter-spacing: 0.38em;
   color: #fff;
   text-decoration: none;
   font-family: 'Poppins';
   font-style: normal;
   font-size: 11px; 
   font-weight: normal; 
   cursor: pointer;
}

.topMenu_sub1 .topMenu-nav-link ~ .topMenu-nav-link{
   border: none;
}
.topMenu_sub1 .topMenu-nav-link{
   border-right: 1px solid #fff;
   padding: 0px 20px;
   margin: auto;
   letter-spacing: 0.38em;
}

.topMenu_sub1 .topMenu-nav-link:hover {
   color: #e3001a;
}

.topMenu_sub1{
   margin: auto;
   display: flex;
   justify-content: center;
   flex-wrap: wrap;
}

.navbar-collapse{
   padding: 10px;
}

.topMenu{
   display: flex;
   align-items: center;
   justify-content: center;
   width: 95%;
   border-bottom: 1px solid #fff;
   padding-bottom: 10px;
   margin-bottom: 10px;
   flex-wrap: wrap
}

.topMenu-Button{
   background-color: #E3B258;
   padding: 7px 30px 7px 25px;
   color: #3C3A32;
   text-decoration: none;
   font-family: 'Poppins';
   font-style: normal;
   font-weight: 500;
   font-size: 14px;
   -webkit-transition: all .2s ease-out;
   -moz-transition: all .2s ease-out;
   -o-transition: all .2s ease-out;
   transition: all .2s ease-out;
}

.topMenu-Button:hover,
.topMenu-Button:active,
.topMenu-Button:focus {
   background-color: #fff;
   color: #3C3A32;
}

.topMenu-Button img.carrito {
   width: auto;
   height: 20px;
   margin-right: 5px;
}

.fp-warning, .fp-watermark{
   display: none;
}

.dropdown-menu.show{
   display: flex !important;
}

.dropDownSection{
   padding: 20px 5px;
   display: flex;
   flex-direction: column;
   align-items: center;
}

.headerDestacado{
   text-transform: uppercase;
}

.dropDownLinea1{
   position: relative;
   width: 54px;
   height: 6px;
   background: linear-gradient(93.85deg, #F2EC62 22.49%, #DEAD00 175.6%);
   margin-top: 15px;
   margin-bottom: 10px;
}

.dropDownLinea2{
   position: relative;
   width: 54px;
   height: 6px;
   background: linear-gradient(106deg, #62F2AD 0.95%, #00DEDE 100%);
   margin-top: 15px;
   margin-bottom: 10px;
}

.dropDownLinea3{
   position: relative;
   width: 54px;
   height: 6px;
   background: linear-gradient(106deg, #9062F2 0.95%, #1B053F 100%);
   margin-top: 15px;
   margin-bottom: 10px;
}

.dropDownLinea4{
   position: relative;
   width: 54px;
   height: 6px;
   background: linear-gradient(106deg, #FD8C4C 0.95%, #D07017 100%);
   margin-top: 15px;
   margin-bottom: 10px;
}

.dropDownLinea5{
   position: relative;
   width: 54px;
   height: 6px;
   background: linear-gradient(106deg, #C0BB66 0.95%, #83702A 100%);
   margin-top: 15px;
   margin-bottom: 10px;
}

.dropDownLinea6{
   position: relative;
   width: 54px;
   height: 6px;
   background: linear-gradient(106deg, #F26262 0.95%, #BA0505 100%);
   margin-top: 15px;
   margin-bottom: 10px;
}

.dropDownLinea7{
   position: relative;
   width: 54px;
   height: 6px;
   background: linear-gradient(106deg, #878787 0.95%, #3D3C3C 100%);
   margin-top: 15px;
   margin-bottom: 10px;
}

.dropDownSection .dropdown-item{
   color: #727272;
   font-family: Playfair Display;
   font-size: 14px;
   font-style: normal;
   line-height: 1.1;
}

.menu-quesos-desktop .dropDownSection .dropdown-item {
   text-align: center;
}

.tituloDropDown{
   font-family: 'Poppins' !important;
   font-weight: 600;
   font-size: 14px !important;
   text-align: center;
   letter-spacing: 0.08em;
   text-transform: uppercase;
   padding-top: 15px !important;
}

.espacioHeader{
   height: 200px;
}

.espacioHeader2{
   height: 110px;
}

.logoAbreviado{
   height: 40px;
   display: none;
}

/* Footer */

.footer{
   background-color: #3C3A32;
   padding: 35px 50px 10px 50px;
}

.CertificadoCalidad{
   margin: 0 8px 15px 8px;
}

.topFooter .boton{
   background-color: #E3B258;
   padding: 10px 20px !important;
   font-family: 'Poppins';
   font-style: normal;
   font-weight: 500;
   font-size: 13px;
   line-height: 42px;
   letter-spacing: 0.38em;
   color: #3C3A32 !important;
   text-decoration: none;
   border-right: none !important;
}

.topFooter .boton svg {
   width: 16px;
   height: 18px;
   margin-left: 10px;
   vertical-align: text-bottom;
   transition: transform 0.3s ease;
}

.topFooter a.boton:hover svg {
   transform: translateX(5px);
}

.topFooter > div > a{
   font-family: 'Poppins';
   font-style: normal;
   font-weight: 500;
   font-size: 13px;
   text-align: center;
   letter-spacing: 0.12em;
   text-transform: uppercase;
   color: #FFFFFF !important;
   border-right: 1px solid #fff;
   padding: 0px 15px !important;
   text-decoration: none;
}

.lineaBlanca{
   border: 1px solid #fff;
}

.footerTituloNaranja{
   font-family: 'Poppins';
   font-style: normal;
   font-weight: 700;
   font-size: 13px;
   line-height: 35px;
   color: #E3B258;
}

.footerTextoNaranja{
   font-family: 'Poppins';
   font-style: normal;
   font-weight: 500;
   font-size: 13px;
   line-height: 22px;
   color: #E3B258;
}

.footerTextoBlanco{
   font-family: 'Poppins';
   font-style: normal;
   font-weight: 500;
   font-size: 13px;
   line-height: 22px;
   color: #FFFFFF;
   text-decoration: none;
}

.menuFooter{
   text-align: right;
   display: flex;
   flex-wrap: wrap;
   justify-content: flex-end
}

.footerBloque21{
   margin: 0 auto;
   width: 220px;
   height: auto;
}

.mensaje{
   width: calc(60% + 10px);
}

.checkbox{
   width: auto;
}

.footerBloque31{
   display: flex;
   align-items: center;
   justify-content: center;
}

.footerBloque31 img{
   margin-right: 15px;
   width: 35px;
   height: auto;
}

.iconoRRSS{
   margin-right: 20px;
   vertical-align: middle;
}

.enlaceFooter{
   font-family: 'Poppins';
   font-style: normal;
   font-weight: 500;
   font-size: 13px;
   line-height: 98px;
   text-align: center;
   letter-spacing: 0.12em;
   color: #FFFFFF;
   text-decoration: none;
}

.enlaceFooterBorder{
   padding-right: 20px;
   margin-right: 20px;
   border-right: 1px solid #fff !important;
}

.checkmark{
   background-color: #3C3A32;
}

.iconosFooter{
   text-align: right;
}

.legalCorto{
   color: #fff;
   font-size: 10px;
   margin-top: 15px;
}

.gradientePrensa {
   background: linear-gradient(180deg, #f8f8f8 0%, #cfcfcf 100%);
}

.box-descargable {
   background: #fff;
   padding: 20px;
   margin-top: 20px;
}

.prensa .titulo {
   color: #47453C;
   text-align: center;
   font-size: 60px;
   font-family: 'Playfair Display';
   font-weight: 700;
   text-transform: uppercase;
   width: 900px;
   margin: 0 auto;
}

.prensa .titulo2 {
   font-family: 'Poppins';
}

.prensa {
   padding: 100px 0px;
   position: relative;
   width: 100%;
   overflow: hidden;
}

.prensa .box-descargable h3 {
   font-family: 'Playfair Display';
   text-transform: uppercase;
   font-size: 21px;
   font-weight: 600;
   margin-bottom: 15px;
}

.prensa .box-descargable p {
   margin-bottom: 40px;
}

.prensa .box-descargable a {
   color: #E2001A;
   text-decoration: none;
}

.prensa .box-descargable a svg {
   width: 16px;
   height: 18px;
   margin-right: 10px;
   vertical-align: text-bottom;
}
.prensa .box-descargable a svg path {
   fill: #E2001A;
}
.ue-logo img {
   height: 50px;
   width: auto;
   margin-top: 15px;
}
.btn-comprar-carrito {
   display: inline-flex;
   justify-content: center;
   background-color: #E3B258;
   padding: 12px 30px 12px 25px;
   color: #3C3A32;
   text-decoration: none;
   text-transform: uppercase;
   font-style: normal;
   font-weight: 500;
   font-size: 16px;
   transition: all .2s ease-out;
}
.btn-comprar-carrito:hover {
   color: #3C3A32;
   background: #c99e4f;
}
.btn-comprar-carrito img {
   width: 21px;
   height: auto;
   margin-right: 10px;
}
.boton-compra {
   position: relative;
   padding: 13px;
   width: 70px;
   height: 70px;
   border-radius: 50%;
   background-color: #3c3a32;
   border: none;
   cursor: pointer;
   overflow: hidden;
   transition: all 0.2s ease-in-out;
}
.boton-compra .icono-carrito {
   width: 33px;
   height: 33px;
   filter: invert(100%);
}
.boton-compra .icono-carrito-hover {
   height: 33px;
   width: 33px;
   margin-right: 6px;
   margin-left: 6px;
   filter: invert(100%);
}
.comprar-texto {
   display: none;
   font-size: 16px;
   color: #fff;
   opacity: 0;
   margin-right: 6px;
   font-weight: bold;
}
.boton-compra:hover {
   width: auto;
   height: 70px;
   border-radius: 0;
}
.boton-compra:hover .comprar-texto {
   opacity: 1;
   display: inline-block;
}
.boton-compra:hover img.icono-carrito {
   display: none;
}
@media (min-width: 991px){
   .navbar-expand-lg .navbar-nav .dropdown-menu {
      position: absolute;
      transform: translateX(-39%);
   }
   .menu-quesos-desktop {
      display: flex;
   }
}
@media (max-width: 991px) {
   .boton-compra .icono-carrito-hover {
      height: 33px;
      width: 33px;
   }
   .boton-compra .icono-carrito-hover,
   .boton-compra .comprar-texto {
      display: inline-block;
      opacity: 1;
   }
   .boton-compra {
      width: auto;
      height: 60px;
      border-radius: 0;
   }
   .boton-compra:hover {
      height: 60px;
   }
   .boton-compra img.icono-carrito {
      display: none;
   }
   .topFooter > div > a{
      padding: 0px 12px !important;
   }
   .menuFooter{
      justify-content: start;
   }
   .nav-link, .navbar-nav .nav-link.active, .navbar-nav .show>.nav-link {
      padding: 15px 0 !important;
   }
   .menu-quesos-mobile {
      display: block;
   }
   .nav-link,
   .navbar-nav .nav-link.active,
   .navbar-nav .show>.nav-link {
      border-right: 0;      
   }
   .navbar-nav .dropdown-toggle.nav-link {
      border-right: 0;
   }
   .footer {
      padding: 35px 15px 10px 15px;
   }
   .ue-logo {
      text-align: center;
      margin-bottom: 25px;
   }
}

@media (max-width: 768px) {
   .iconosFooter{
      text-align: center;
   }
   .topMenu_sub2{
      margin-top: 10px;
   }
   .legalCorto {
      width: 100%;
      text-align: justify;
   }
   .text-justify {
      text-align: justify;
   }
   .prensa .titulo{
      font-size: 36px !important;
      margin: 0 auto;
      line-height: 50px !important;
   }
   .topMenu-Button {
      padding: 5px 25px;
      font-size: 13px;
   }
}