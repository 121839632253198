.fp-overflow {
   background: linear-gradient(90deg, #F2E963 0%, #FEC904 100%);
}

.principal{
   padding: 280px 0 0;
   width: 100%;
   height: 100%;
   display: flex;
   flex-direction: column;
   align-items: center;
   position: relative;
   transition: background 2s ease-out;
   z-index: 9;
}

.principal .Titulo{
   font-family: 'Poppins';
   font-style: normal;
   font-weight: 700;
   font-size: 80px;
   line-height: 1;
   text-align: center;
   text-transform: uppercase;
   color: #FFFFFF;
   padding: 0 15px;
}

.principal .Titulo span{
   font-family: 'Playfair Display';
}

.lineaRoja{
   width: 73px;
   height: 10px;
   left: calc(50% - 73px/2 - 0.5px);
   margin-top: 10px;
   margin-bottom: 30px;
   background: linear-gradient(90deg, #E2001A 0%, #fc512b 100%)
}

.principal .TextoBlanco{
   font-family: Poppins;
   color: #fff;
   text-align: center;
   width: 31%;
   padding: 0 15px;
}

.principal img.img-portada {
   bottom: -80px;
   margin-top: -80px;
   position: relative;
   width: auto;
   height: 330px;
}

#quesoTierno{
   padding: 160px 0px 0px;
   position: absolute;
   top: 0px;
   width: 100%;
   padding-top: 30px;
   overflow: clip;
}

.principal::-webkit-scrollbar{
   display: none;
}

.btn-comprar-producto-box {
   position: absolute;
   bottom: 50px;
   left: 1000px;
   right: 0;
}

.textoPremios{
   color: #807A60;
   text-align: center;
   font-family: Poppins;
   font-size: 24px;
   font-style: normal;
   font-weight: 500;
   line-height: 98px; /* 408.333% */
   letter-spacing: 5.76px;
   text-transform: uppercase;
   padding-right: 20px;
}

.contenedorPremios{
   display: flex;
   justify-content: center;
   padding-top: 20px;
}

.contenedorPremios .premio{
   height: 100px;
   margin-left: 20px;
}

.gradiente-quesos-oveja {
   background: linear-gradient(105deg, #FD8C4C 0%, #D98446 100%);
}
.gradiente-quesos-manchegos {
   background: linear-gradient(105deg, #F2EC62 0%, #E4CF55 100%);
}
.gradiente-quesos-manchegos-ecologicos {
   background: linear-gradient(105deg, #62F2AD 0%, #7BE5C3 100%);
}
.gradiente-quesos-manchegos-leche-cruda {
   background: linear-gradient(105deg, #9062F2 0%, #583E9F 100%);
}
.gradiente-quesos-oveja-leche-cruda {
   background: linear-gradient(105deg, #C0BB66 0%, #A39956 100%);
}
.gradiente-quesos-oveja-recetas {
   background: linear-gradient(105deg, #F26262 0%, #C74741 100%);
}
.gradiente-quesos-crema {
   background: linear-gradient(105deg, #878787 0%, #3D3C3C 100%);
}

.gradient1 {
   background: linear-gradient(105deg, #F2E963 0%, #F2DB5A 100%);
}
.gradient2 {
   background: linear-gradient(105deg, #FBBB03 0%, #DBAC3C 100%);
}
.gradient3 {
   background: linear-gradient(105deg, #F7D6A4 0%, #FAAA31 100%);
}
.gradient4 {
   background: linear-gradient(105deg, #69412F 0%, #805640 100%);
}
.gradient5 {
   background: linear-gradient(105deg, #C6121C 0%, #FB1F96 100%);
}
.gradient6 {
   background: linear-gradient(105deg, #78B829 0%, #B1BD42 100%);
}
.gradient7 {
   background: linear-gradient(105deg, #EA5418 0%, #E08334 100%);
}
.gradient8 {
   background: linear-gradient(105deg, #468FC9 0%, #589CBF 100%);
}
.gradient9 {
   background: linear-gradient(105deg, #00A3C6 0%, #1CBABA 100%);
}
.gradient10 {
   background: linear-gradient(105deg, #1F493F 0%, #00983E 100%);
}
.gradient11 {
   background: linear-gradient(105deg, #1F493F 0%, #00983E 100%);
}
.gradient12 {
   background: linear-gradient(105deg, #1D1E1B 0%, #746D68 100%);
}
.gradient13 {
   background: linear-gradient(105deg, #2B3087 0%, #1B89C7 100%);
}
.gradient14 {
   background: linear-gradient(105deg, #2A4F25 0%, #5A6B2E 100%);
}
.gradient15 {
   background: linear-gradient(105deg, #8D4736 0%, #B77C20 100%);
}
.gradient16 {
   background: linear-gradient(105deg, #BA9C38 0%, #F5CF46 100%);
}
.gradient17 {
   background: linear-gradient(105deg, #6C2209 0%, #894F31 100%);
}
.gradient18 {
   background: linear-gradient(105deg, #004A99 0%, #3773BA 100%);
}
.gradient19 {
   background: linear-gradient(105deg, #CAAF86 0%, #B49F7A 100%);
}
.gradient20 {
   background: linear-gradient(105deg, #18442D 0%, #417752 100%);
}
.gradient21 {
   background: linear-gradient(105deg, #582673 0%, #753294 100%);
}
.gradient22 {
   background: linear-gradient(105deg, #ABA3AD 0%, #A09BA1 100%);
}
.gradient23 {
   background: linear-gradient(105deg, #C0CBD0 0%, #B7BFC1 100%);
}
.gradient24 {
   background: linear-gradient(105deg, #F9B422 0%, #EFC547 100%);
}
.gradient25 {
   background: linear-gradient(105deg, #C72E36 0%, #982928 100%);
}
.gradient26 {
   background: linear-gradient(105deg, #057A37 0%, #50A15A 100%);
}
.gradient27 {
   background: linear-gradient(105deg, #FF010B 0%, #EB7E31 100%);
}
.gradient28 {
   background: linear-gradient(105deg, #9A4230 0%, #AC7534 100%);
}
.gradient29 {
   background: linear-gradient(105deg, #334E21 0%, #7B7C49 100%);
}
.gradient30 {
   background: linear-gradient(105deg, #F32424 0%, #E76F62 100%);
}
.gradient31 {
   background: linear-gradient(105deg, #FFD3AC 0%, #E58A3A 100%);
}
.gradient32 {
   background: linear-gradient(105deg, #C997CB 0%, #8C3293 100%);
}
.gradient33 {
   background: linear-gradient(105deg, #E9817C 0%, #D6443C 100%);
}
.gradient34 {
   background: linear-gradient(105deg, #F4C560 0%, #DB990D 100%);
}
.gradient35 {
   background: linear-gradient(105deg, #77F3BA 0%, #229C64 100%);
}
.gradient36 {
   background: linear-gradient(105deg, #C1C1C1 0%, #808385 100%);
}

#categoria-cremas .circuloContenedor {
   top: -130px;
}

.quesoSubcategoria .tituloProducto{
   font-family: 'Playfair Display';
   font-style: normal;
   font-weight: 700;
   font-size: 200px;
   line-height: 98px;
   text-align: center;
   text-transform: uppercase;
}

.quesosOvejaTierno{
   padding: 160px 0px 0px;
   position: relative;
   width: 100%;
   overflow: hidden;
}

.quesosOvejaSemicurado{
   padding: 160px 0px 0px;
   position: relative;
   width: 100%;
   overflow: hidden;
}

.quesoSubcategoria{
   padding: 130px 0 0 0;
   position: relative;
   width: 100%;
   overflow: hidden;
}

.quesosOvejaAnejo{
   padding: 160px 0px 0px;
   position: relative;
   width: 100%;
   height: 100vh;
   overflow: hidden;
   color: #fff;
}

.quesosOvejaAnejo h2{
   color: #fff;
}
.quesosOvejaAnejo .tiempoMaduracion{
   color: #E3E3E2;
}

.tiempoMaduracion{
   font-family: 'Poppins';
   font-style: normal;
   font-weight: 500;
   font-size: 24px;
   text-align: center;
   letter-spacing: 0.24em;
   text-transform: uppercase;
   margin-bottom: 25px;
}
.tiempoMaduracionNegro {
   color: #807A60;
}
.tituloNegro {
   color: #47453C;
}

.tituloQueso{
   font-family: 'Poppins';
   font-style: normal;
   font-weight: 700;
   font-size: 60px;
   line-height: 1;
   text-align: center;
   text-transform: uppercase;
   padding: 0 15px;
   margin-bottom: -80px;
}

.tituloQueso span{
   font-family: 'Playfair Display';
}

.cunaQueso{
   position: relative;
   z-index: 3;
}

svg.svg-circulo {
   max-width: 140px;
   font-size: 42px;
   position: relative;
   z-index: 3;
   left: calc(50% - 475px);
   top: 240px;
}

#categoria-cremas svg.svg-circulo {
   left: calc(50% - 525px);
   top: 210px;
}

@keyframes rotate {
   from{
      transform: rotate(0deg);
   }
   to{
      transform: rotate(360deg);
   }
}

.quesoSubcategoria .box-images {
   margin-top: 30px;
}
.quesoSubcategoria .cunaQueso {
   width: 280px;
   position: relative;
   top: 145px;
   left: calc(50% - 140px);
}

.textcircle{
   animation: rotate 50s cubic-bezier(0, 0, 0.41, 0.38);
   animation-iteration-count: infinite;
   transform-origin: 250px 250px;
}

.circulo{
   border: 1px solid #000;
   width: 45px;
   height: 45px;
   border-radius: 25px;
   display: flex;
   justify-content: center;
   align-items: center;
   font-size: 22px;
   font-weight: normal;
   cursor: pointer;
   -webkit-transition: all .2s ease-out;
   -moz-transition: all .2s ease-out;
   -o-transition: all .2s ease-out;
   transition: all .2s ease-out;
}

.circulo:hover{
   transform: scale(1.1);
}

.circulo.circuloBlanco {
   border-color: #fff;
}

.ficha-producto {
   font-size: 13px;
   letter-spacing: 4px;
   margin-left: 17px;
}

.circuloContenedor{
   position: relative;
   z-index: 3;
   display: flex;
   align-items: center;
   left: calc(50% + 90px);
   top: -170px;
}

.sliderDiv{
   height: 1812px;
   width: 100%;
   background-color: #fff;
   overflow: hidden;
   display: flex;
   flex-wrap: wrap;
   justify-content: space-around;
   align-content: flex-start;
}

.slider {
   width: 100%;
   height: 420px;
   overflow: hidden;
   position: relative;
   bottom: 0%;
   background-color: #fff;
   margin-top: 20px;
}

.imagenSlider{
   height: auto;
   margin-bottom: 0px;
}

.fondoTransicionSlider{
   display: flex;
   flex-wrap: wrap;
   justify-content: space-around;
   align-content: flex-start;
   height: 80%;
   overflow: hidden;
}

.queso_product .queso {
   position: absolute;
   z-index: 2;
   width: 510px;
   left: calc(50% - 155px);
   top: 250px;
}

#producto-queso-crema .queso_product .queso {
   width: 600px;
   left: calc(50% - 300px);
   top: 260px;
}

.queso_product svg.textoVueltas {
   max-width: 190px;
   font-size: 42px;
   position: absolute;
   z-index: 3;
   left: calc(50% + 175px);
   top: 560px;
}

.TextoProducto{
   position: relative;
   z-index: 2;
}

.info_producto {
   margin-top: -230px;
}

.info_producto .center {
   margin-top: -60px;
   position: relative;
   top: -290px;
}

.queso_product .info_producto img.cunaQueso {
   left: calc(50% - 370px);
   position: absolute;
   width: 300px;
   top: 400px;
}

.supertextoProducto{
   font-family: 'Poppins';
   font-style: normal;
   font-weight: bold;
   font-size: 36px;
   line-height: 1;
   text-align: center;
   text-transform: uppercase;
   padding: 0 15px;
   margin-bottom: 0;
   margin-top: 20px;
}

.tituloProducto{
   font-family: 'Playfair Display';
   font-style: normal;
   font-weight: 700;
   font-size: 200px;
   text-align: center;
   text-transform: uppercase;
   position: relative;
   top: -30px;
   padding: 0 15px;
}

.principalProducto .supertextoProducto {
   margin-top: -80px;
}
.principalProducto .tituloProducto {
   margin-top: 0px;
}
.principalProducto .cunaQueso {
   
}
.principalProducto .queso {
   left: 53%;
   top: 62%;
}

.PrincipalProducto {
   background: #F9F9F9;
   display: flex;
   flex-direction: column;
   align-items: center;
   position: relative;
   padding-bottom: 610px;
}

#producto-queso-crema .PrincipalProducto {
   padding-bottom: 500px;
}

.color1 {
   color: #F2E963;
}
.color2 {
   color: #FBBB03;
}
.color3 {
   color: #F7D6A4;
}
.color4 {
   color: #69412F;
}
.color5 {
   color: #C6111C;
}
/* 
.quesosOvejaTierno .tituloProducto{
   font-family: 'Playfair Display';
   font-style: normal;
   font-weight: 700;
   font-size: 250px;
   line-height: 98px;
   text-align: center;
   text-transform: uppercase;
}

.quesosOvejaSemicurado .tituloProducto{
   font-family: 'Playfair Display';
   font-style: normal;
   font-weight: 700;
   font-size: 250px;
   line-height: 98px;
   text-align: center;
   text-transform: uppercase;
}

.quesosOvejaAnejo .tituloProducto{
   font-family: 'Playfair Display';
   font-style: normal;
   font-weight: 700;
   font-size: 250px;
   line-height: 98px;
   text-align: center;
   text-transform: uppercase;
} */

@keyframes barrido-hacia-arriba {
   0% {
     transform: translateY(0);
     opacity: 1;
   }
   100% {
     transform: translateY(-1000%);
     opacity: 0;
   }
}
@keyframes barrido-hacia-arriba2 {
   0% {
     transform: translateY(0);
     opacity: 1;
   }
   100% {
     transform: translateY(-10000%);
     opacity: 0;
   }
}
 
.salida-pantalla {
   animation: barrido-hacia-arriba 1s ease-out forwards;
}

.salida-pantalla2 {
   animation: barrido-hacia-arriba2 1s ease-out forwards;
}

@keyframes barrido-hacia-abajo {
   0% {
     transform: translateY(-1000%);
     opacity: 0;
     display: none;
   }
   100% {
     transform: translateY(0);
     opacity: 1;
     display: block;
   }
}
@keyframes barrido-hacia-abajo2 {
   0% {
     transform: translateY(-10000%);
     opacity: 0;
     display: none;
   }
   100% {
     transform: translateY(0);
     opacity: 1;
     display: block;
   }
}
 
.entrar-pantalla-abajo {
   animation: barrido-hacia-abajo 1s ease-out forwards;
}

.entrar-pantalla-abajo2 {
   animation: barrido-hacia-abajo2 1s ease-out forwards;
}

@keyframes entrada-hacia-arriba {
   0% {
     transform: translateY(1000%);
     opacity: 0;
   }
   100% {
     transform: translateY(0);
     opacity: 1;
   }
}
@keyframes entrada-hacia-arriba2 {
   0% {
     transform: translateY(10000%);
     opacity: 0;
     display: none;
   }
   100% {
     transform: translateY(0);
     opacity: 1;
     display: block;
   }
}
 
.entrar-pantalla {
   animation: entrada-hacia-arriba 1s ease-out forwards;
}

.entrar-pantalla2 {
   animation: entrada-hacia-arriba2 1s ease-out forwards;
}

@media(max-width: 1920px) {
   .principal .TextoBlanco {
      width: 60%;
   }
   .principal img.img-portada {
      
   }
   .queso_product .tituloProducto {
      font-size: 160px;
   }
   .queso_product .queso {
      top: 210px;
   }
   #producto-queso-crema .queso_product .queso {
      top: 220px;
   }  
   .queso_product .info_producto img.cunaQueso {
      top: 360px;
   }
   .queso_product svg.textoVueltas {
      top: 520px;
   }
   .info_producto .center {
      top: -240px;
   }
}

@media(max-width: 1280px) {
   .principal .TextoBlanco {
      width: 90%;
   }
   .principal img.img-portada {
      width: 65%;
      height: auto;
   }
   .queso_product .tituloProducto {
      font-size: 100px;
   }
   .queso_product .queso {
      top: 160px;
   }
   #producto-queso-crema .queso_product .queso {
      top: 190px;
      width: 350px;
      left: calc(50% - 170px);
   }
   .queso_product .info_producto img.cunaQueso {
      top: 310px;
   }
   .queso_product svg.textoVueltas {
      top: 480px;
   }
   #producto-queso-crema .queso_product svg.textoVueltas {
      top: 330px;
      left: calc(50% - -90px);
   }
   .info_producto .center {
      top: -170px;
   }
   .btn-comprar-producto-box {
      left: 0;
   }
}

@media(max-width: 991px) {
   img.imagenSlider {
      width: 220px;
   }
   .fondoTransicionSlider .sliderDiv {
      transform: none !important;
   }
}

@media(max-width: 768px) {
   img.imagenSlider {
      width: 110px;
   }
   .quesoSubcategoria .cunaQueso {
      top: 123px;
      left: calc(50% - 80px);
      width: 200px;
   }
   .circulo {
      width: 35px;
      height: 35px;
   }
   .circuloContenedor {
      left: calc(50% + 20px);
      top: -130px;
   }
   #categoria-cremas .circuloContenedor {
      top: -90px;
   }
   .quesoSubcategoria .box-images {
      margin-top: -40px;
   }
   .circuloContenedor .ficha-producto {
      margin-left: 10px;
   }
   svg.svg-circulo {
      max-width: 90px;
      left: calc(50% - 310px);
      top: 190px;
   }
   #categoria-cremas svg.svg-circulo {
      max-width: 90px;
      left: calc(50% - 340px);
      top: 170px;
   }
   .principal .Titulo {
      font-size: 45px;
   }
   .tituloQueso {
      font-size: 40px;
   }
   .tiempoMaduracion {
      font-size: 18px;
   }
   .slider {
      height: 180px;
   }
   .queso_product .info_producto img.cunaQueso {
      left: calc(50% - 170px);
      width: 150px;
      top: 250px;
   }
   .queso_product .queso {
      width: 260px;
      left: calc(50% - 95px);
      top: 175px;
   }
   .queso_product svg.textoVueltas {
      left: calc(50% + 75px);
      top: 310px;
      max-width: 140px;
      font-size: 30px;
   }
   .queso_product .tituloProducto {
      font-size: 45px;
      top: 0;
   }
   .supertextoProducto {
      font-size: 25px;
   }
   .PrincipalProducto {
      padding-bottom: 390px;
   }
   #producto-queso-crema .PrincipalProducto {
      padding-bottom: 450px;
   }
   .btn-comprar-producto-box {
      bottom: 0;
   }
   .info_producto .center {
      top: -140px;
   }
}