.inconformistas{
   background: linear-gradient(102deg, #E6BB6C 0%, #FFE4B4 100%);
   padding: 100px 0px;
   position: relative;
   width: 100%;
   overflow: hidden;
}

.inconformistas .titulo{
   color: #47453C;
   text-align: center;
   font-size: 60px;
   font-family: 'Playfair Display';
   font-weight: 700;
   text-transform: uppercase;
   width: 900px;
   margin: 0 auto;
}

.inconformistas .titulo2{
   font-family: 'Poppins';
}

.inconformistas .texto{
   color: #4A473E;
   text-align: center;
   font-size: 16px;
   font-family: Poppins;
   margin: 0 auto;
}

.inconformistas .imagenes{
   width: 80%;
   margin: 0 auto;
   margin-top: 50px;
   display: flex;
   justify-content: space-around;
   flex-wrap: wrap;
   align-items: baseline;
}

.inconformistas .imagen{
   display: flex;
   flex-direction: column;
   align-items: center;
   width: 320px;
   margin-left: 15px;
   margin-right: 15px;
   margin-bottom: 50px;
}

.inconformistas .imagen img{
   height: 200px;
}

.inconformistas .imagen .tituloI{
   color: #3C3A32;
   text-align: center;
   font-size: 20px;
   font-family: Playfair Display;
   font-weight: 700;
   line-height: normal;
   text-transform: uppercase;
   margin-top: 10px;
   margin-bottom: 15px;
}

.inconformistas .imagen .texto{
   color: #4A473E;
   text-align: center;
   font-size: 16px;
   font-family: Poppins;
}

.impacto{
   background: #3C3A32;
   padding: 100px 0px;
   position: relative;
   width: 100%;
   overflow: hidden;
}

.impacto .titulo2{
   font-family: Playfair Display;
}

.impacto .titulo{
   color: #FFF;
   text-align: center;
   font-size: 60px;
   font-family: Poppins;
   font-weight: 700;
   text-transform: uppercase;
   max-width: 800px;
   margin: 0 auto;
   margin-bottom: 20px;
}

.impacto .impactos{
   width: 80%;
   margin: 0 auto;
   margin-bottom: 50px;
}

.impacto .impactos .paso{
   display: flex;
   align-items: center;
}

.impacto .impactos .paso .numero{
   border-radius: 98px;
   border: 4px solid #E2001A;
   min-width: 88px;
   height: 88px;
   display: flex;
   justify-content: center;
   align-items: center;
   margin-right: 20px;
}

.impacto .impactos .paso .numero p{
   color: #E2001A;
   text-align: center;
   font-size: 50px;
   font-family: Poppins;
   font-weight: 700;
   line-height: 98px;
   text-transform: uppercase;
   margin-bottom: 0px;
}

.impacto .impactos .paso .contenido .tituloP{
   color: #E6BC6D;
   font-size: 20px;
   font-family: Playfair Display;
   font-weight: 700;
   line-height: normal;
   text-transform: uppercase;
   text-align: left;
   margin: 10px 0px;
}

.impacto .impactos .paso .contenido .texto{
   color: #FFF;
   font-size: 16px;
   font-family: Poppins;
   line-height: 27px;
}

.idi{
   background: linear-gradient(106deg, #E2001A 13.78%, #FEC904 100%);
   padding: 100px 0px;
   position: relative;
   width: 100%;
   overflow: hidden;
}

.idi img{
   position: absolute;
   max-width: 250px;
   left: calc(50% - 110px);
}

.idi .titulo{
   color: #EFEFF0;
   text-align: center;
   font-size: 108px;
   font-family: Playfair Display;
   font-weight: 700;
   letter-spacing: -3.36px;
   text-transform: uppercase;
   width: 50%;
   margin: 0 auto;
   margin-top: 170px;
}

.idi .texto{
   color: #FFF;
   text-align: center;
   font-size: 20px;
   font-family: Poppins;
   font-weight: 600;
   text-transform: uppercase;
   width: 70%;
   margin: 0 auto;
   margin-top: 30px;
}

.laboratorio{
   background: linear-gradient(185deg, #F8F8F8 0%, #CFCFCF 100%);
   padding: 100px 0px;
   position: relative;
   width: 100%;
   overflow: hidden;
}

.laboratorio .texto{
   color: #3C3A32;
   text-align: center;
   font-size: 16px;
   font-family: Poppins;
   width: 70%;
   margin: 0 auto;
}

.laboratorio .titulo{
   color: #3C3A32;
   text-align: center;
   font-size: 60px;
   font-family: Playfair Display;
   font-weight: 700;
   text-transform: uppercase;
   width: 70%;
   margin: 0 auto;
}

.laboratorio .titulo2{
   font-family: Poppins;
}

.laboratorio .imagen{
   display: flex;
   justify-content: center;
   margin-top: 10px;
}

.laboratorio .imagen img{
   max-width: 350px;
   min-width: 175px;
}

@media (max-width: 991px) {

   .paises .contenedorTitulo img {
      left: 50%;
      top: -3%;
      width: 90px;
   }

   .paises .image img{
      width: 90%;
   }

   .quesoSorprendente .detalle .textoFondo{
      font-size: 80px;
   }

   .cabeceraInnovacion .cabecera, .inconformistas .titulo, .impacto .titulo, .idi .titulo, .laboratorio .titulo{
      font-size: 50px !important;
      width: 90%;
   }

   .idi .titulo{
      margin-top: 200px;
   }

   .idi .texto{
      width: 90%;
   }

   .inconformistas .imagenes .imagen img{
      height: 150px;
   }

   .laboratorio .texto{
      width: 90%;
   }
   .inconformistas .imagen .tituloI {
      font-size: 28px;
   }
   .impacto .impactos .paso .numero {
      margin-right: 0;
      margin-top: 20px;
   }
}

@media (max-width: 768px) {
   

   .impacto .impactos .paso{
      flex-wrap: wrap;
      justify-content: center;
   }

   .impacto .impactos .paso .contenido .tituloP{
      text-align: center;
   }

   .impacto .impactos .paso .contenido{
      text-align: center;
   }

   .cabeceraInnovacion .cabecera{
      font-size: 48px !important;
      margin: 0 auto;
      line-height: 1.3;
   }

   .inconformistas .titulo{
      font-size: 36px !important;
      margin: 0 auto;
      line-height: 50px !important;
   }

   .ini .titulo{
      line-height: normal;
      padding-top: 60px;
   }
}