.cabeceraPremios{
   height: 100%;
   width: 100%;
   background-size: cover;
   background-position: center;
   position: relative;
}

.cabeceraPremios::before{
   content: "";
   position: absolute;
   top: 0;
   left: 0;
   width: 100%;
   height: 100%;
   background-color: rgba(60, 58, 50, 0.62);
}

.premioConsecuencia{
   background: linear-gradient(102deg, #E6BB6C 0%, #f3d090 100%);
   padding: 100px 0px;
   position: relative;
   width: 100%;
   overflow: hidden;
}

.premioConsecuencia .titulo{
   color: #47453C;
   text-align: center;
   font-size: 50px;
   font-family: 'Playfair Display';
   font-weight: 700;
   text-transform: uppercase;
   max-width: 700px;
   margin: 0 auto;
}

.premioConsecuencia .titulo2{
   font-family: 'Poppins';
}

.premioConsecuencia .image{
   display: flex;
   justify-content: center;
}

.premioConsecuencia .image img{
   width: 300px;
   height: auto;
   margin: 20px 0;
}

.premioConsecuencia .texto{
   color: #4A473E;
   text-align: center;
   font-size: 16px;
   font-family: Poppins;
   max-width: 1000px;
   margin: 0 auto;
   width: 95%;
}

.quesosPremiados{
   background: linear-gradient(185deg, #F8F8F8 0%, #CFCFCF 100%);
   padding: 100px 0px;
   position: relative;
   width: 100%;
   overflow: hidden;
}

.quesosPremiados .titulo2{
   font-family: Poppins;
}

.quesosPremiados .titulo{
   color: #47453C;
   text-align: center;
   font-size: 60px;
   font-family: Playfair Display;
   font-style: normal;
   font-weight: 700;
   text-transform: uppercase;
   width: 60%;
   margin: 0 auto ;
}

.quesosPremiados .titulosQuesos{
   margin-top: 50px;
   margin-bottom: 50px;
   margin: 50px auto 0 auto;
}

.linea-bottom-queso {
   width: 73px;
   height: 10px;
   margin-left: 15px;
}
.linea-color1 {
   background: #78B829;
}
.linea-color2 {
   background: #EA5418;
}
.linea-color3 {
   background: #468FC9;
}
.linea-color4 {
   background: #F3E843;
}
.linea-color5 {
   background: #FFD9A3;
}
.linea-color5-2 {
   background: #C56242;
}
.linea-color6 {
   background: #C6121D;
}
.linea-color7 {
   background: #F9B422;
}
.linea-color8 {
   background: #CAAF86;
}
.linea-color9 {
   background: #18442D;
}
.linea-color10 {
   background: #582673;
}
.linea-color11 {
   background: #A81921;
}
.linea-color12 {
   background: #057A37;
}
.linea-color13 {
   background: #334E21;
}
.linea-color14 {
   background: #F42525;
}
.linea-color15 {
   background: #6D240B;
}
.linea-color16 {
   background: #D1A030;
}

.quesosPremiados .tituloQueso{
   color: #47453C;
   text-align: left;
   font-size: 32px;
   font-family: Poppins;
   font-style: normal;
   font-weight: 700;
   text-transform: uppercase;
   margin-bottom: 15px;
}

.premio-img-box {
   text-align: right;
}

.titulosQuesos .relativeContainer {
   border-top: 1px solid #c1c1c1;
   padding: 40px 0;
}

.premio-img-box img {
   height: 150px;
   width: auto;
   padding: 0 15px;
}

.box-queso-premio {
   padding: 25px 0 65px 0;
}

.quesosPremiados .tituloQueso2{
   font-family: Playfair Display;
}

.premiosValorados{
   background: #313232;
   padding: 100px 0px;
   position: relative;
   width: 100%;
   overflow: hidden;
}

.premiosValorados .titulo{
   color: #FFF;
   text-align: center;
   font-size: 60px;
   font-family: Playfair Display;
   font-style: normal;
   font-weight: 700;
   text-transform: uppercase;
   width: 50%;
   margin: 0 auto;
   margin-bottom: 25px;
   margin-top: 60px;
   z-index: 2;
   position: inherit;
}

.premiosValorados .titulo2{
   font-family: Poppins;
   color: #E3B258;
}

.premiosValorados .contenedorTexto{
   width: 38%;
   margin: 0 auto;
   padding: 0 15px;
}

.premiosValorados .texto{
   color: #FFF;
   text-align: center;
   font-size: 16px;
   font-family: Poppins;
   font-style: normal;
   font-weight: 400;
}

.premiosValorados .premioP{
   display: flex;
   align-items: center;
   width: 70%;
   flex-wrap: wrap;
   justify-content: center;
}

.premiosValorados .premios .premioP {
   padding-bottom: 10px;
   padding-right: 15px;
}

.premiosValorados .corona{
   position: absolute;
   left: 50%;
   transform: translateX(-50%);
}

.premiosValorados .premioP .image{
   min-width: 150px;
   margin-right: 30px;
   display: flex;
   justify-content: center;
   margin-bottom: 20px;
}

.premiosValorados .premioP .premiotexto{
   width: 70%;
}

.premiosValorados .premioP .premiotexto .cheese{
   color: #F69438;
   font-size: 20px;
   font-family: Playfair Display;
   font-style: normal;
   font-weight: 700;
   text-transform: uppercase;
}

.premiosValorados .premioP .premiotexto .texto{
   color: #EFEFF0;
   font-size: 16px;
   font-family: Poppins;
   font-style: normal;
   font-weight: 400;
   text-align: justify;
}

.btn-comprar {
   background-color: #E3B258;
   padding: 12px 30px 12px 25px;
   color: #3C3A32;
   text-decoration: none;
   font-family: 'Poppins';
   font-style: normal;
   font-weight: 500;
   font-size: 18px;
   text-transform: uppercase;
   border: 1px solid transparent;
   -webkit-transition: all .2s ease-out;
   -moz-transition: all .2s ease-out;
   -o-transition: all .2s ease-out;
   transition: all .2s ease-out;
}

.btn-comprar:hover {
   background-color: #fff;
   color: #3C3A32;
   border: 1px solid #9a9a9a;
}

img.img-carrito {
   height: 25px;
   width: auto;
   margin-right: 5px;
}

.relativeContainer{
   position: relative;
}

.ruedaQueso{
   position: absolute;
   height: 130px;
   left: -4%;
}

.manchegoSemicurado{
   width: 259px;
   height: 114px;
   background: rgb(120, 184, 41);
   border-radius: 57px;
   position: absolute;
   top: 0;
   left: 50%;
   transform: translateX(-50%);
   display: flex;
   align-items: center;
   justify-content: flex-end;
   padding-right: 30px;
}

.manchegoCurado{
   width: 380px;
   height: 114px;
   background: #EA5418;
   border-radius: 57px;
   position: absolute;
   top: 0;
   left: 50%;
   transform: translateX(-50%);
   display: flex;
   align-items: center;
   justify-content: flex-end;
   padding-right: 30px;
}

.manchegoAnejo{
   width: 450px;
   height: 114px;
   background: #468FC9;
   border-radius: 57px;
   position: absolute;
   top: 0;
   left: 50%;
   transform: translateX(-50%);
   display: flex;
   align-items: center;
   justify-content: flex-end;
   padding-right: 30px;
}

.ovejaTierno{
   width: 240px;
   height: 114px;
   background: #F3E843;
   border-radius: 57px;
   position: absolute;
   top: 0;
   left: 50%;
   transform: translateX(-50%);
   display: flex;
   align-items: center;
   justify-content: flex-end;
   padding-right: 30px;
}

.ovejaTrufaNegra{
   width: 640px;
   height: 114px;
   background: #C6121D;
   border-radius: 57px;
   position: absolute;
   top: 0;
   left: 50%;
   transform: translateX(-50%);
   display: flex;
   align-items: center;
   justify-content: flex-end;
   padding-right: 30px;
}

.ovejaTrufaBlanca{
   width: 240px;
   height: 114px;
   background: #F9B422;
   border-radius: 57px;
   position: absolute;
   top: 0;
   left: 50%;
   transform: translateX(-50%);
   display: flex;
   align-items: center;
   justify-content: flex-end;
   padding-right: 30px;
}

.ovejaBoletus{
   width: 360px;
   height: 114px;
   background: #CAAF86;
   border-radius: 57px;
   position: absolute;
   top: 0;
   left: 50%;
   transform: translateX(-50%);
   display: flex;
   align-items: center;
   justify-content: flex-end;
   padding-right: 30px;
}

.ovejaAceitunaNegra{
   width: 240px;
   height: 114px;
   background: #18442D;
   border-radius: 57px;
   position: absolute;
   top: 0;
   left: 50%;
   transform: translateX(-50%);
   display: flex;
   align-items: center;
   justify-content: flex-end;
   padding-right: 30px;
}

.ovejaAjoNegro{
   width: 330px;
   height: 114px;
   background: #582673;
   border-radius: 57px;
   position: absolute;
   top: 0;
   left: 50%;
   transform: translateX(-50%);
   display: flex;
   align-items: center;
   justify-content: flex-end;
   padding-right: 30px;
}

.ovejaDiablo{
   width: 410px;
   height: 114px;
   background: #A81921;
   border-radius: 57px;
   position: absolute;
   top: 0;
   left: 50%;
   transform: translateX(-50%);
   display: flex;
   align-items: center;
   justify-content: flex-end;
   padding-right: 30px;
}

.ovejaJalapenos{
   width: 410px;
   height: 114px;
   background: #057A37;
   border-radius: 57px;
   position: absolute;
   top: 0;
   left: 50%;
   transform: translateX(-50%);
   display: flex;
   align-items: center;
   justify-content: flex-end;
   padding-right: 30px;
}

.ovejaRomero{
   width: 461px;
   height: 114px;
   background: #334E21;
   border-radius: 57px;
   position: absolute;
   top: 0;
   left: 50%;
   transform: translateX(-50%);
   display: flex;
   align-items: center;
   justify-content: flex-end;
   padding-right: 30px;
}

.ovejaTradicion{
   width: 360px;
   height: 114px;
   background: #F42525;
   border-radius: 57px;
   position: absolute;
   top: 0;
   left: 50%;
   transform: translateX(-50%);
   display: flex;
   align-items: center;
   justify-content: flex-end;
   padding-right: 30px;
}

.premioImg{
   padding-right: 20px;
   max-height: 100px;
}

@media (max-width: 991px) {
   .premioConsecuencia .image img{
      width: 150px;
   }
   .premiosValorados .premioP .premiotexto .cheese{
      text-align: center;
   }
   .premiosValorados .titulo, .quesosPremiados .titulo, .premioConsecuencia .titulo, .cabecera, .cabeceraTexto {
      font-size: 50px !important;
   }
   .premiosValorados .premios .premioP div {
      width: 100%;
   }
   .premiosValorados .premios .premioP {
      width: 100%;
      padding: 0 35px;
   }
   .premiosValorados .premioP .image {
      margin-right: 0;
   }
   .premiosValorados {
      padding: 100px 0px 0 0;
   }
   .quesosPremiados .titulo {
      width: 100%;
   }
   .quesosPremiados .titulosQuesos {
      max-width: 100%;
   }
   .premiosValorados .contenedorTexto {
      width: 100%;
   }
   .quesosPremiados .tituloQueso{
      text-align: center;
   }
   
   .premio-img-box {
      text-align: center;
      margin-bottom: 15px;
   }
   .premio-img-box img {
      height: 100px;
      width: auto;
   }
   .linea-bottom-queso {
      margin: 0 auto;
   }
}

@media (max-width: 768px) {
   .cabeceraTituloContenedor{
      width: 98%;
   }
   .premiosValorados .titulo, .quesosPremiados .titulo, .premioConsecuencia .titulo, .cabecera, .cabeceraTexto{
      font-size: 30px !important;
   }

   .quesosPremiados .tituloQueso{
      font-size: 24px;
   }
   .premiosValorados .premios .premioP {
      padding: 0 15px;
   }
}